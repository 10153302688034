import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import RetailJobs from "../views/RetailJobs.vue";
import RetailLocation from "../views/RetailLocation.vue";
import HqJobs from "../views/HqJobs.vue";
import HqDepartment from "../views/HqDepartment.vue";
import Job from "../views/Job.vue";
import Apply from "../views/Apply.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/referral/:identifier?",
    name: "Referral",
    component: () =>
    import(/* webpackChunkName: "PageNotFound" */ "../views/Referral.vue"),
  },
  {
    path: "/:language?",
    name: "Home",
    component: Home,
  },
  {
    path: "/:language/hq/jobs",
    name: "HQJobs",
    component: HqJobs,
  },
  {
    path: "/:language/hq/department/:departmentLine",
    name: "HqDepartment",
    component: HqDepartment,
  },
  {
    path: "/:language/retail/jobs",
    name: "Jobs",
    component: RetailJobs,
  },
  {
    path: "/:language/retail/location/:locationLine",
    name: "Location",
    component: RetailLocation,
  },
  {
    path: "/:language/:location/job/:jobid",
    name: "Job",
    component: Job,
  },
  {
    path: "/:language/:location/apply/:jobid",
    name: "Apply",
    component: Apply,
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;
