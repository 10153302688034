<template>
  <v-container class="w-100 pl-0 pr-0" fluid>
    <template v-if="privacyStatementContent != null && applyFormContent != null && defaultTextContent != null && location != null && job != null">
      <!-- HEADER  APPLY FOR THE JOB-->
      <v-row class="mx-0 px-0">
          <!-- v-if="(location == 'hq' && hqJobDetailHeaderImageContent != null) || (location == 'retail' && currentStore != null)"
          :src="location == 'hq' ? `${apiPublicUrl}${hqJobDetailHeaderImageContent.image}` : `${apiPublicUrl}${currentStore.content.image}`" -->
        <!-- <v-img
         :src="
            location == 'hq'
              ? hqJobDetailHeaderImageContent != null
                ? `${apiPublicUrl}${hqJobDetailHeaderImageContent.image}`
                : `${apiPublicUrl}${currentStore.content.image}`
              : currentStore != null
              ? `${apiPublicUrl}${currentStore.content.image}`
              : `${apiPublicUrl}/uploads/20220404/1649071583_f0f98e139c947962150e.jpg`
          " -->
           <v-img
          v-if="(location == 'hq' && hqJobDetailHeaderImageContent != null) || location == 'retail'"
          :src="
            location == 'hq'
              ? `${apiPublicUrl}${hqJobDetailHeaderImageContent.image}`
              : currentStore != null && 'content' in currentStore
              ? `${apiPublicUrl}${currentStore.content.image}`
              : `${apiPublicUrl}/uploads/20220404/1649071583_f0f98e139c947962150e.jpg`
          "
          min-height="500"
          max-height="500"
          class="header blur overlay"
          content-class="header-item"
        >
          <v-row class="fill-height mt-3 px-10" align="center" justify="center">
            <div class="white--text text-center">
              <h1 class="text-h5 text-sm-h3 text-md-h4 text-lg-h2 li-text-shadow font-weight-black text-md-h4 text-uppercase text-no-break liHeroHeading" v-html="job.title" />
              <h2 class="text-h6 text-md-h5 mb-3 font-weight-light li-text-shadow text-uppercase" v-text="defaultTextContent.applyForTheJob" />
              <!-- <h1 class="text-h5 text-sm-h3 text-md-h4 text-lg-h2 font-weight-black text-md-h4 text-uppercase text-no-break li-text-shadow" v-html="job.title" /> -->
              <v-col cols="12" class="m-auto">
                <!-- DETAILS -->
                <div class="mt-5 text-center">
                  <!-- <liLogos :brand="findStoreBrand(job)" page="jobDetail" :tags="job.tags" /> -->
                  <!-- <hr /> -->
                  <!-- DESKTOP -->
                  <div class="mt-7 mb-7 d-none d-sm-flex">
                    <div class="mx-auto" v-if="job.address.city != ''">
                      <v-icon color="white" class="mr-2">mdi-map-marker</v-icon>
                      <span class="mr-6">{{ job.address.city }}</span>
                    </div>
                    <div class="mx-auto" v-if="'hours' in job && job.hours != ''">
                      <v-icon color="white" class="mr-2">mdi-clock-time-eight-outline</v-icon>
                      <span :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-6'"
                        >{{ job.hours }}
                        <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                        {{ defaultTextContent.hour }}</span
                      >
                      <template v-if="'employment' in job && job.employment != ''"> | {{ job.employment[0] | capitalizeFirstLetter }} </template>
                    </div>
                  </div>
                  <!-- MOBILE -->
                  <div class="mt-2 mb-2 d-block d-sm-none text-caption">
                    <span class="d-block mb-2">
                      <template v-if="job.address.city != ''">
                        <v-icon color="white" small class="mr-2">mdi-map-marker</v-icon>
                        <span class="mr-6">{{ job.address.city }}</span>
                      </template>
                      <template v-if="'hours' in job && job.hours != ''">
                        <v-icon color="white" class="mr-2">mdi-clock-time-eight-outline</v-icon>
                        <span :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-6'"
                          >{{ job.hours }}
                          <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                          {{ defaultTextContent.hour }}</span
                        >
                        <template v-if="'employment' in job && job.employment != ''"> | {{ job.employment[0] | capitalizeFirstLetter }} </template>
                      </template>
                    </span>
                  </div>
                  <!-- <hr /> -->
                </div>
                <!-- /.END DETAILS -->
              </v-col>
            </div>
          </v-row>
        </v-img>
      </v-row>
      <v-row class="mx-0 px-0 bg-light">
        <v-container>
          <!-- APPLY -->
          <v-row class="px-2 px-sm-10 pt-0 pb-10">
            <v-container class="py-0">
              <v-row>
                <!-- THE FORM -->
                <v-col cols="12" md="8" lg="8" xl="6" class="pt-0 mx-auto" style="">
                  <v-overlay v-if="formLoading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-overlay>
                  <v-sheet elevation="3" class="pa-5 pb-10" style="margin-top: -50px; position: relative; z-index: 2">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field v-model="name" :rules="nameRules" :label="`${applyFormContent.name.label}*`" required></v-text-field>
                      <v-text-field v-model="email" :rules="emailRules" :label="`${applyFormContent.email.label}*`" required></v-text-field>
                      <v-text-field v-model="phone" :label="applyFormContent.phone.label"></v-text-field>
                      <v-textarea v-model="motivation" :label="applyFormContent.motivation.label"></v-textarea>
                      <v-row>
                        <v-col cols="12" class="mt-4">
                           <v-file-input
                            :rules="resumeRules"
                            :label="applyFormContent.resume.buttonLabel"
                            prepend-icon="mdi-file"
                            ref="resumeFileInput"
                            @change="filesChange2"
                            solo
                            chips
                            small-chips
                            required
                            truncate-length="30"
                            accept=".doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf,.pdf"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <template v-if="photoFile != null">
                            <v-avatar width="100" height="100">
                              <img class="mb-3" :src="photoFile.base64" />
                            </v-avatar>
                            <br />
                          </template>
                          <v-file-input
                            required
                            :rules="photoRules"
                            @click:clear="clearPhoto"
                            :label="applyFormContent.photo.buttonLabel"
                            prepend-icon="mdi-camera"
                            ref="photoFileInput"
                            chips
                            small-chips
                            solo
                            truncate-length="30"
                            @change="photoChange2"
                            accept="image/png, image/jpeg,image/jpg"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <!-- PRIVACY -->
                      <div class="mt-7 text-center">
                        <hr />
                        <v-checkbox v-model="checkbox" :rules="[(v) => !!v || applyFormContent.agree]" required>
                          <template v-slot:label>
                            <div>
                              {{ applyFormContent.terms1 }}
                              <a @click="sheet = !sheet" class="font-weight-black text-decoration-underline">
                                {{ applyFormContent.terms2 }}
                              </a>
                            </div>
                          </template>
                        </v-checkbox>
                        <hr />
                      </div>
                      <!-- ./ PRIVACY -->
                      <!-- SUBMIT -->
                      <div class="mt-7 text-center">
                        <v-btn color="light" elevation="0" text class="mr-4" @click="reset">
                          {{ applyFormContent.reset }}
                        </v-btn>
                        <v-btn :disabled="!valid" class="btn-cta mr-4" @click="validate">
                          {{ applyFormContent.apply }}
                        </v-btn>
                      </div>
                      <!-- ./ SUBMIT -->
                    </v-form>
                  </v-sheet>
                </v-col>
              </v-row>
              <!-- DEVELOPMENT -->
              <!-- <div class="mt-10 px-5">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Development - Available
                      attributes</v-expansion-panel-header
                    >
                    <v-expansion-panel-content style="overflow: hidden">
                      <pre>{{ job }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div> -->
            </v-container>
          </v-row>
        </v-container>
      </v-row>
      <v-snackbar v-model="apply" centered color="light" elevation="24" timeout="-1">
        <span v-html="applyFormContent.applyThanks"></span>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click.prevent="closeApply">
            {{ applyFormContent.applyReset }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-left" height="80vh">
          <div class="col-12 col-md-6 m-auto" style="position: relative">
            <div class="text-h5">
              {{ applyFormContent.privacyStatementTitle }}
              <v-btn @click="sheet = !sheet" color="primary" dark absolute top right fab>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="col-12 col-md-6 m-auto overflow-y-auto" style="max-height: 65vh" v-html="privacyStatementContent"></div>
          <div class="col-12 col-md-6 m-auto text-center">
            <v-btn color="primary" @click="sheet = !sheet">
              {{ applyFormContent.closeTerms }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>

    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "Apply",
  data() {
    return {
      source: "",
      defaultSource: "pme-legend.com",
      apiPublicUrl: API_URL,
      // RESUME
      resumeFileToBig: false,
      resume64: { name: null, content: null },
      resumeFile: null,
      // resume: null,
      // PHOTO
      photoFileToBig: false,
      photo64: { name: null, content: null },
      photoFile: null,
      formLoading: false,
      apply: false,
      sheet: false,
      valid: true,
      allowedPhotos: ['image/jpeg','image/jpg','image/png','image/bmp'],
      allowedResume: ['application/pdf','application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      photoRules: [
        (v) => {
          if (v === null || v === undefined) {
            return true;
          } else if (v != undefined && v != null && v.size < 1900000) {
            return true;
          } else if (v != undefined && v != null && !this.allowedPhotos.includes(v.type)) {
            return this.applyFormContent.photo.wrongType;
          } else {
            return this.applyFormContent.photo.toBig;
          }
        },
      ],
      resumeRules: [
        (v) => {
          if (v === null || v === undefined) {
            return this.applyFormContent.resume.required;
          } else if (v != undefined && v != null && v.size < 1900000) {
            return true;
          } else if (v != undefined && v != null && !this.allowedResume.includes(v.type)) {
            return this.applyFormContent.resume.wrongType;
          } else {
            return this.applyFormContent.resume.toBig;
          }
        },
      ],
      phone: "",
      phoneRules: [(v) => !!v || this.applyFormContent.phone.required, (v) => (v && v.length <= 150) || ""],
      name: "",
      nameRules: [(v) => !!v || this.applyFormContent.name.required, (v) => (v && v.length <= 150) || ""],
      email: "",
      emailRules: [(v) => !!v || this.applyFormContent.email.required, (v) =>  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.applyFormContent.email.valid],
      motivation: "",
      checkbox: false,
    };
  },
  computed: {
    currentStore() {
      if (this.job != null) {
        return this.findStore(this.job);
      } else {
        return null;
      }
    },
    language() {
      return this.$route.params.language != undefined ? this.$route.params.language : null;
    },
    location() {
      return this.$route.params.location != undefined ? this.$route.params.location : null;
    },
    content() {
      return this.location != null ? this.appConfig.pages.job[this.location] : null;
    },
    job() {
      if (this.$route.params.jobid != undefined && this.$store.getters["liApiStore/getJobs"](this.location) != null) {
        return this.$store.getters["liApiStore/getJob"](this.location, this.$route.params.jobid);
      } else {
        this.$store.dispatch("liApiStore/GET_JOBS", {
          location: this.location,
          language: this.language,
        });
        return null;
      }
      // return null;
    },
  },
  activated() {
    this.setupTitle();
  },
  mounted() {
    this.setupTitle();
  },
  watch: {
    job(val) {
      if (val != null) {
        this.$title = `Apply for: ${this.job.title} | ${this.location}`;
      }
    },
  },
  methods: {
    clearPhoto(e) {
      console.debug(e);
    },
    closeApply() {
      this.apply = false;
      this.formLoading = false;
      this.setupTitle();
    },
    triggerResumeFileInput() {
      this.$refs.resumeFileInput.click();
    },
    resetFiles() {
      this.resumeFile = null;
      this.resume64.name = null;
      this.resume64.content = null;
      this.photoFile = null;
      this.photo64.name = null;
      this.photo64.content = null;
    },
    filesChange2(e) {
      this.resumeFileToBig = false;
      this.resume64.name = null;
      this.resume64.content = null;
      // get the files
      let fileTarget = e;
      let file = fileTarget;
      if (file != null) {
        // Make new FileReader
        let reader = new FileReader();
        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + " kB",
            base64: reader.result,
            file: file,
          };
          console.log(fileInfo);
          if (Math.round(file.size / 1000) < 2000) {
            // Push it to the state
            this.resumeFile = fileInfo;
            this.resume64.name = `${file.name}`;
            this.resume64.content = reader.result;
          } else {
            this.resumeFileToBig = true;
          }
        }; // reader.onload
      }
    }, // filesChange2()
    photoChange2(e) {
      this.photoFileToBig = false;
      this.photo64.name = null;
      this.photo64.content = null;
      this.photoFile = null;
      // get the files
      let fileTarget = e;
      let file = fileTarget;
      if (file != null) {
        // Make new FileReader
        let reader = new FileReader();
        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
          let fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + " kB",
            base64: reader.result,
            file: file,
          };
          if (Math.round(file.size / 1000) < 3000) {
            // Push it to the state
            this.photoFile = fileInfo;
            this.photo64.name = `${file.name}`;
            this.photo64.content = reader.result;
          } else {
            this.photoFileToBig = true;
          }
        }; // reader.onload
      }
    }, // photoChange2()
    validate() {
      this.$refs.form.validate();
      this.formLoading = true;
      setTimeout(() => {
        if (this.valid) {
          const obj = {
            params: {
              location: this.location,
              language: this.language,
            },
            candidate: {
              language: this.language,
              jobId: this.$route.params.jobid,
              source: this.source,
              name: this.name.trim(),
              email: this.email.trim(),
              phone: this.phone.trim(),
              motivation: this.motivation.trim(),
            },
          };
          if (this.resume64.name != null && this.resume64.content != null) {
            obj.candidate.resume = this.resume64;
          }
          if (this.photo64.name != null && this.photo64.content != null) {
            obj.candidate.photo = this.photo64;
          }
          this.$store.dispatch("liApiStore/POST_CANDIDATE", obj).then((response) => {
            if (response.message === "success") {
              this.apply = true;
              this.$title = `Thank you for your application: ${this.job.title} | ${this.location}`;
              this.reset();
            }
          });
        } else {
          this.formLoading = false;
        }
      }, 1000);
    },
    reset() {
      this.resetFiles();
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setupTitle() {
      this.source = this.$route.query.sid != undefined ? this.$route.query.sid : this.defaultSource;

      // FASION UNITED ONLY
      this.source = this.$route.query.utm_source != undefined ? this.$route.query.utm_source : this.defaultSource;

      if (this.applyForm == null) {
        this.fetchAppApplyForm();
      }
      if (this.privacyStatement == null) {
        this.fetchAppPrivacyStatement();
      }
      if (this.location == "hq") {
        if (this.hqJobDetailHeaderImage == null) {
          this.fetchHqJobHeaderImage();
        }
        if (this.hqJobDetailMoodImage == null) {
          this.fetchHqJobMoodImage();
        }
        if (this.departmentItems == null) {
          this.fetchDepartmentItems();
        }
      }
      if (this.location == "retail") {
        if (this.storeItems == null) {
          this.fetchStoreItems();
        }
      }
      if (this.job != null) {
        this.$title = `Apply for: ${this.job.title} | ${this.location}`;
      }
    },
  },
};
</script>
