<template>
  <v-container class="w-100 px-0" fluid>
    <template v-if="page != null && pageContent != null && defaultTextContent && cultureTitleBlockContent != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img :src="`${apiPublicUrl}${pageContent.image}`" min-height="400px" max-height="400px" class="header blur overlay">
          <v-row class="fill-height mt-10" align="center" justify="center">
            <div class="white--text text-center px-10">
              <h1 class="text-lg-h2 text-sm-h3 text-h6 text-no-break text-uppercase liHeroHeading mb-3" v-html="pageContent.headerTitle" />
              <div class="text-sm-h5 text-p white--text text-center li-text-shadow text-uppercase" v-html="pageContent.headerSubTitle" />
            </div>
          </v-row>
        </v-img>
      </v-row>
      <!-- VIDEO -->
      <template v-if="pageContent.videoCode && pageContent.videoCode != ''">
        <v-container class="pt-16" style="max-width: 1200px">
          <v-row>
            <v-col cols="12" md="12" lg="12" class="m-auto">
              <liVimeoVideo :vimeoId="pageContent.videoCode" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- /. VIDEO -->
      <!-- JOBS HEADER -->
      <v-row>
        <v-col cols="12" md="12" lg="12" class="m-auto text-black text-center my-5">
          <h2 class="text-uppercase text-md-h4 text-h4 font-weight-bold" v-if="pageContent.jobstitle && pageContent.jobstitle != ''" v-html="pageContent.jobstitle" />
          <div v-if="pageContent.jobssubtitle && pageContent.jobssubtitle != ''" class="text-p text-center mt-7 mb-7 mx-auto" style="max-width: 1000px" v-html="pageContent.jobssubtitle" />
        </v-col>
        <template v-if="pageContent.videoCode && pageContent.videoCode != ''">
          <v-col cols="12" md="12" lg="12" class="m-auto">
            <liVimeoVideo :vimeoId="pageContent.videoCode" />
          </v-col>
        </template>
      </v-row>
      <!-- JOBS -->
      <v-row class="mx-0 px-0 mb-15 bg-light">
        <v-container style="max-width: 1900px">
          <v-row class="grey lighten-5 px-sm-10 py-10 jobs">
            <!-- JOB ITEMS -->
            <template v-if="filteredJobs != null">
              <v-card tile elevation="0" v-for="job in filteredJobs" :key="job.id" class="text-black job d-flex flex-column text-black" :to="`/${language}/${location}/job/${job.id}`">
                <v-card-title class="text-no-break font-weight-bold text-black text-uppercase liTitle" v-text="job.title"></v-card-title>
                <v-card-subtitle class="mt-1 text-no-break text-black" v-html="job.summary"></v-card-subtitle>
                <v-spacer></v-spacer>
                <!-- <liLogos :brand="findStoreBrand(job)" :tags="job.tags" /> -->
                <v-divider />
                <v-card-actions>
                  <template v-if="job.address.city != ''">
                    <v-icon small class="mr-1">mdi-map-marker</v-icon>
                    <span class="text-caption mr-4">{{ job.address.city }}</span>
                  </template>
                  <template v-if="'hours' in job && job.hours != ''">
                    <v-icon small class="mr-1">mdi-clock-time-eight-outline</v-icon>
                    <span class="text-caption" :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-4'"
                      >{{ job.hours }}<span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span
                      >{{ defaultTextContent.hour }}</span
                    >
                    <template v-if="'employment' in job && job.employment != ''">
                      <span class="text-caption mr-4"> | {{ job.employment[0] | capitalizeFirstLetter }}</span>
                    </template>
                  </template>
                </v-card-actions>
              </v-card>
            </template>
            <template v-else>
              <div class="text-center w-100">
                <v-progress-circular indeterminate color="primary" />
              </div>
            </template>
            <template v-if="filteredJobs.length == 0">
              <!-- NO JOBS CARD -->
              <v-card tile class="job d-flex grey lighten-5" elevation="0">
                <div class="py-15 px-15 text-center w-100" align="center">
                  <h5 class="text-h5 font-weight-bold mb-5">
                    {{ defaultTextContent.noJobsFoundHeader }}
                  </h5>
                  <v-btn :to="`/${language}/${location}/jobs`">
                    {{ defaultTextContent.noJobsFoundText }}
                  </v-btn>
                </div>
              </v-card>
            </template>
          </v-row>
          <!-- END / JOBS -->
        </v-container>
      </v-row>
      <!-- MAP AND ADDRESS -->
      <v-row class="mx-0 px-0 mt-0">
        <v-container style="max-width: 1400px">
          <v-row>
            <v-col cols="12" md="4">
              <GmapMap
                v-if="page.g_lat != '' && page.g_long != ''"
                :center="{ lat: Number(page.g_lat), lng: Number(page.g_long) }"
                :zoom="15"
                map-type-id="terrain"
                style="width: 100%; height: 300px"
                :options="mapOptions"
              >
                <GmapMarker
                  :icon="markers[page.brand]"
                  :position="{
                    lat: Number(page.g_lat),
                    lng: Number(page.g_long),
                  }"
                />
              </GmapMap>
            </v-col>
            <v-col cols="12" md="8">
              <v-sheet class="bg-light py-5 px-5 py-md-15 px-md-15 d-flex" height="100%">
                <div class="align-self-center">
                  <h5 class="text-h5 mb-3 font-weight-bold liHeroHeading text-uppercase" v-html="page.name" />
                  <p>
                    {{ page.street }} {{ page.number }} <br />
                    {{ page.postal }}<br />
                    {{ page.city }}
                  </p>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!-- ABOUT -->
      <v-row
        class="mx-0 px-0 mt-0"
        v-if="(pageContent.storeTitle && pageContent.storeTitle != '') || (pageContent.storeDesc && pageContent.storeDesc != '') || (pageContent.imageStore2 && pageContent.imageStore2 != '')"
      >
        <v-container style="max-width: 1400px">
          <v-row>
            <v-col
              cols="12"
              :md="pageContent.imageStore2 && pageContent.imageStore2 != '' ? 5 : 12"
              v-if="(pageContent.storeTitle && pageContent.storeTitle != '') || (pageContent.storeDesc && pageContent.storeDesc != '')"
            >
              <v-sheet color="#ffffff" class="py-5 px-5 py-md-10 px-md-10 d-flex" height="100%">
                <div class="align-self-center">
                  <h5 class="text-h5 mb-3 font-weight-bold" v-html="pageContent.storeTitle" />
                  <span v-html="pageContent.storeDesc" />
                </div>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="7" v-if="pageContent.imageStore2 && pageContent.imageStore2 != ''">
              <v-sheet color="#ffffff"  height="100%">
                <v-img :src="`${apiPublicUrl}${pageContent.imageStore2}`" class="" v-if="pageContent.imageStore2 && pageContent.imageStore2 != ''" />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!-- CARROUSEL -->
      <v-row class="mx-0 px-0 mb-15 CARROUSEL" v-if="pageContent.teamSlider && pageContent.teamSlider != '' && pageContent.teamSlider != '[]'">
        <v-container class="pt-16" style="max-width: 1200px">
          <v-row>
            <v-col cols="12" md="12" lg="12" class="m-auto">
              <div class="primary--text mt-5 text-center">
                <div class="my-3 text-h2 font-weight-bold" v-if="pageContent.teamTitle && pageContent.teamTitle != ''" v-html="pageContent.teamTitle" />
                <div v-if="pageContent.teamSubTitle && pageContent.teamSubTitle != ''" class="text-p text-center mt-7 mb-7 mx-auto" style="max-width: 1000px" v-html="pageContent.teamSubTitle" />
              </div>
            </v-col>
          </v-row>
          <liCarrousel :data="JSON.parse(pageContent.teamSlider)" />
        </v-container>
      </v-row>
      <!-- IMAGE -->
      <v-row class="mx-0 px-0 mt-0" v-if="pageContent.imageStore1 && pageContent.imageStore1 != ''">
        <v-container fluid class="px-0">
          <v-img :src="`${apiPublicUrl}${pageContent.imageStore1}`" class="" />
        </v-container>
      </v-row>
      <!-- CULTURE -->
      <v-row class="mx-0 px-0 bg-light">
        <v-container class="pb-16 pt-12" style="max-width: 1400px">
          <!-- CULTURE TITLE -->
          <v-col cols="12" md="12" lg="12" class="pt-0 m-auto text-center text-black">
            <h2 class="text-md-h4 text-h4 mb-3 text-black" v-html="cultureTitleBlockContent.culturetitle" />
            <div class="text-p text-center mb-7" v-html="cultureTitleBlockContent.culturetext" />
          </v-col>
          <!-- CULTURE ITEMS  -->
          <liCultureItems v-if="cultureItems != null" :key="fullUrl" :itemsToShow="3" />
        </v-container>
      </v-row>
      <!-- OUR BRANDS  -->
      <v-row class="mx-0 px-0" v-if="page.brand == 'jb' && ourBrandsBlockContent != null">
        <v-container class="pt-16" fluid>
          <liCarrouselThunmbs :data="ourBrandsBlockContent" />
        </v-container>
      </v-row>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;

export default {
  title: "",
  name: "Location",
  data() {
    return {
      apiPublicUrl: API_URL,
      filter: [],
      location: "retail",
    };
  },
  created() {
    this.fetchURL();
  },
  computed: {
    page() {
      if (this.storeItems != null) {
        let page = this.storeItems.find((store) => store.fullurl == this.fullUrl);
        console.log(page);
        if (page != undefined) {
          return page;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    pageContent() {
      console.log(this.page);
      if (this.page != null) {
        if (typeof this.page.content == "string") {
          return JSON.parse(this.page.content);
        } else {
          return this.page.content;
        }
      } else {
        return null;
      }
    },
    locationLine() {
      return this.$route.params.locationLine != undefined ? decodeURI(this.$route.params.locationLine) : null;
    },
    currentLocation() {
      return this.locationLine != undefined && this.locationLine != null && this.places != null ? this.places.find((place) => place.line == this.locationLine) : null;
    },
    jobs() {
      return this.$store.getters["liApiStore/getJobs"](this.location);
    },
    storeItems() {
      return this.$store.getters["liApiContent/getCollection"](`stores_${this.language}`);
    },
    filteredJobs() {
      if (this.jobs != null && this.locationLine != null) {
        if (this.filter.length > 0) {
          // FILTER STUFF
          return null;
        } else {
          return this.jobs.filter((job) => {
            if (job.address.line != undefined) {
              return job.address.line.replaceAll(",", "") == this.locationLine.replaceAll(",", "");
            }
          });
        }
      } else {
        return null;
      }
    },
    places() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.address != null && job.address != undefined && job.address != "" && job.address.city != "") {
            if (!filterItems.some((item) => item.line === job.address.line)) {
              filterItems.push(job.address);
            }
          }
        });
        return filterItems.sort((a, b) => (a.city > b.city ? 1 : a.city === b.city ? (a.size > b.size ? 1 : -1) : -1));
      } else {
        return null;
      }
    },
    careerLevels() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.careerLevel != null && job.careerLevel != undefined && job.careerLevel.trim() != "") {
            if (!filterItems.includes(job.address.city)) {
              filterItems.push(job.careerLevel);
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
    employments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.employment != null && job.employment != undefined && job.employment != "") {
            if (job.employment.length > 1) {
              job.employment.forEach((employment) => {
                if (!filterItems.includes(employment)) {
                  filterItems.push(this.capitalizeFirstLetter(employment));
                }
              });
            } else {
              if (!filterItems.includes(job.employment[0])) {
                filterItems.push(this.capitalizeFirstLetter(job.employment[0]));
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
  },
  watch: {
    page(val) {
      if (val != null && this.fullUrl != null) {
        this.$title = this.page.pagetitle;
      }
    },
  },
  mounted() {
    // console.log("START -- RETAIL LOCATION VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- RETAIL LOCATION VIEW");
    this.setupPage();
  },
  activated() {
    this.fetchURL();
    console.log("START -- ACTIVATED LOCATION VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- RETAIL LOCATION VIEW");
    this.setupPage();
  },
  methods: {
    setupPage() {
      this.loadJobs();
      if (this.storeItems == null) {
        this.fetchStoreItems();
      }
      // } else {
      //   this.getStore();
      // }
      if (this.cultureItems == null) {
        this.fetchCultureItems();
      }
    },
    // getStore() {
    //   this.page = this.storeItems.find(
    //     (store) => store.fullurl == this.fullUrl
    //   );
    //   if (this.page != undefined) {
    //     if (typeof this.page.content == 'string'){
    //       this.pageContent =  JSON.parse(this.page.content);
    //     }
    //     this.$title = this.page.pagetitle;
    //   } else {
    //     console.log("error");
    //   }
    // },
    loadJobs() {
      this.$store.dispatch("liApiStore/GET_JOBS", {
        location: this.location,
        language: this.language,
      });
    },
  },
};
</script>
