<template>
  <v-app>
    <v-snackbar v-model="snackbar" :timeout="-1"  v-if="host == 'acc1career.justbrands.eu'">
      <div class="w-100 text-center">THIS IS A DEMO ENVIRONMENT - Applications will not be reviewed</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="light" class="bg-primary" v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-system-bar color="red" absolute style="bottom:0px; z-index:20;">
      <div class="w-100 text-center">THIS IS A DEMO ENVIRONMENT - Applications will not be reviewed</div>
    </v-system-bar> -->
    <liNav v-if="$route.name != 'Referral'" />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="`${$route.name}-${$route.params.language}`" />
      </transition>
    </v-main>
    <liFooter v-if="$route.name != 'Referral'" />
  </v-app>
</template>
<script>
const PUBLIC_WEBSITE = process.env.VUE_APP_WEBSITE;
export default {
  name: "JobsApp",
  data: () => ({
    host: null,
    snackbar: true
  }),
  created() {
    this.$store.commit("liApiContent/setWebsite", PUBLIC_WEBSITE);
    this.host = location.hostname;
  },
  watch: {
    language() {
      this.setup();
    },
  },
  mounted() {
    this.setup();
  },
  activated() {
    this.setup();
  },
  methods: {
    setup() {
      if (this.defaultTexts == null) {
        this.fetchAppDefaultTexts();
      }
      if (this.ourBrandsBlock == null) {
        this.fetchAppOurBrands();
      }
      if (this.cultureTitleBlock == null) {
        this.fetchAppCultureTitle();
      }
      this.fetchNavigationItems();
      this.fetchAppDefaultTexts();
      this.fetchAppOurBrands();
      this.fetchAppCultureTitle();
      this.fetchCultureItems();
    },
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";
</style>
