<template>
  <section class="liBrandBlock-1 m-auto" style="max-width:1400px;">
    <v-row class="mt-5 mt-sm-0 mx-5 mx-sm-0 my-10">
      <v-col cols="12" md="6" class="d-flex">
        <div class="my-auto text-black pa-3 pa-md-15">
          <h2 class="text-md-h4 text-h4 mb-3 liHeroHeading" v-if="data.title && data.title != ''" v-text="data.title.replace(/(<([^>]+)>)/gi, '')" />
          <div v-if="data.text && data.text != ''" class="text-p mt-7 mb-7 mx-auto" style="max-width: 1000px" v-html="data.text" />
        </div>
      </v-col>
      <v-col cols="12" md="6" class="d-flex">
        <v-img :aspect-ratio="1.5 / 1" :src="`${apiPublicUrl}${data.images.right}`" />
      </v-col>
    </v-row>
    <v-row class="mt-5 mt-sm-0 mx-5 mx-sm-0 my-10">
        <v-col cols="12" class="d-flex">
        <v-img  :src="`${apiPublicUrl}${data.images.bottom}`" />
      </v-col>
    </v-row>
  </section>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "liBrandBlock1",
  data: () => ({
    apiPublicUrl: API_URL,
  }),
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  methods: {},
};
</script>
