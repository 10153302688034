<template>
  <v-container class="w-100 pl-0 pr-0" fluid>
    <template v-if="pageContent != null && defaultTextContent != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img :src="`${apiPublicUrl}${pageContent.image}`" min-height="400px" max-height="400px" class="header blur">
          <v-row class="fill-height mt-10" align="center" justify="center">
            <div class="white--text text-center">
              <h1 class="text-lg-h2 text-sm-h4 text-h6 text-no-break liHeroHeading mb-3" v-html="pageContent.headerTitle" />
              <div class="text-sm-h5 text-p white--text text-center text-uppercase li-text-shadow" v-html="pageContent.headerSubTitle" />
            </div>
          </v-row>
        </v-img>
      </v-row>
      <!-- WE ARE -->
      <v-row class="mx-0 px-0">
        <v-container class="pt-16" style="max-width: 1400px">
          <v-row>
            <v-col cols="12" md="6" lg="4" class="d-flex">
              <div class="my-auto text-black">
                <h2 class="text-md-h4 text-h4 mb-3 liHeroHeading" v-if="pageContent.introTitle && pageContent.introTitle != ''" v-text="pageContent.introTitle.replace(/(<([^>]+)>)/gi, '')" />
                <div v-if="pageContent.introText && pageContent.introText != ''" class="text-p mt-7 mb-7 mx-auto" style="max-width: 1000px" v-html="pageContent.introText" />
              </div>
            </v-col>
            <!-- VIDEO -->
            <template v-if="pageContent.videoCode && pageContent.videoCode != ''">
              <v-col cols="12" md="6" lg="8">
                <div class="bg-white pa-3 elevation-10 rounded-10">
                  <liVimeoVideo :vimeoId="pageContent.videoCode" />
                </div>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-row>
      <!-- MOOD IMAGE -->
      <v-row class="mx-0 px-0" v-if="pageContent.imageMood1 && pageContent.imageMood1 != ''">
        <v-container fluid class="px-0 pt-16">
          <v-img :src="`${apiPublicUrl}${pageContent.imageMood1}`" class="" />
        </v-container>
      </v-row>
      <!-- DEPARTMENTS -->
      <v-row class="mx-0 px-0 mb-0 mt-15">
        <v-container style="max-width: 1900px">
          <!-- DEPARTMENTS CARROUSEL -->
          <v-carousel class="liDepartments-carrousel" delimiter-icon="mdi-minus" dark next-icon=" " prev-icon=" " hide-delimiter-background hide-delimiters :show-arrows="true" :continuous="false" :height="isAppMobile ? 350 : 350" v-if="arrangedDepartmentItems != null && arrangedDepartmentItems.length > 0">
            <!-- :hide-delimiters="isAppMobile" -->
            <v-carousel-item :key="index" v-for="(row, index) in totalRows(arrangedDepartmentItems, departmentNoOfItems)">
              <v-row style="max-width: calc(100% - 110px); margin: auto">
                <v-col :cols="departmentNoOfCols" :key="department.identifier" v-for="department in changeRow(arrangedDepartmentItems, row, departmentNoOfItems)">
                  <v-card class="mx-0" @click="goTo(department)">
                    <v-img :src="`${apiPublicUrl}${department.card_image}`" :height="200"> </v-img>
                    <div style="position: relative" class="text-p font-weight-bold py-5 px-5">
                      <v-btn absolute class="text-white bg-primary" fab right small top>
                        {{ department.amountOfJobs }}
                      </v-btn>
                      <div class="text-truncate text-uppercase text-black liTitle">
                        {{ department.card_title }}
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-container>
      </v-row>
      <!-- HEADING JOBS -->
      <h1 class="text-h3 text-md-h3 mb-0 font-weight-bold text-uppercase text-center text-black" v-html="pageContent.jobsTitle" />
      <!-- FILTERS-->
      <v-row justify="center" class="bg-light liFilterRow mx-0 px-0 mb-5 mt-15" v-if="(departments != null && departments.length > 0) || (employments != null && employments.length > 0)">
        <template v-if="departments != null && departments.length > 0">
          <v-col cols="12" sm="4" md="3" class="py-0 mt-2">
            <v-select v-model="filterDepartment" :label="defaultTextContent.filterDepartment" :items="departments" dense class="pa-0" prepend-icon="mdi-sitemap-outline" single-line clearable></v-select>
          </v-col>
        </template>
        <template v-if="employments != null && employments.length > 0">
          <v-col cols="12" sm="4" md="3" class="py-0 mt-2">
            <v-select class="pa-0" dense v-model="filterEmployment" :label="defaultTextContent.filterEmployment" :items="employments" prepend-icon="mdi-clock-time-eight-outline" single-line clearable></v-select>
          </v-col>
        </template>
      </v-row>
      <!-- JOBS -->
      <v-row class="mx-0 px-0 mb-15 mt-0 bg-light">
        <v-container style="max-width: 1900px">
          <!-- JOB ITEMS -->
          <v-row class="px-sm-10 pb-10 mt-0 jobs">
            <template v-if="filteredJobs != null">
              <template v-if="filteredJobs.length === 0">
                <!-- NO JOB -->
                <v-card elevation="0" tile class="job px-5 py-5" style="display: flex">
                  {{ defaultTextContent.noJobsFound }}
                </v-card>
              </template>
              <template v-else>
                <v-card tile elevation="0" v-for="job in filteredJobs" :key="job.id" class="text-black job d-flex flex-column text-black" :to="`/${language}/${location}/job/${job.id}`">
                  <v-card-title class="text-no-break font-weight-bold text-black text-uppercase liTitle" v-text="job.title"></v-card-title>
                  <v-card-subtitle class="mt-1 text-no-break text-black" v-html="job.summary"></v-card-subtitle>
                  <v-spacer></v-spacer>
                  <!-- <liLogos  :brand="findStoreBrand(job)" :tags="job.tags"/> -->
                  <v-divider v-if="job.address.city != '' || ('hours' in job && job.hours != '')" />
                  <v-card-actions>
                    <template v-if="job.address.city != ''">
                      <v-icon small class="mr-1">mdi-map-marker</v-icon>
                      <span class="text-caption mr-4">{{ job.address.city }}</span>
                    </template>
                    <template v-if="'hours' in job && job.hours != ''">
                      <v-icon small class="mr-1">mdi-clock-time-eight-outline</v-icon>
                      <span class="text-caption" :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-4'"
                        >{{ job.hours }}
                        <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                        {{ defaultTextContent.hour }}</span
                      >
                      <template v-if="'employment' in job && job.employment != ''">
                        <span class="text-caption mr-4">
                          |
                          {{ job.employment[0] | capitalizeFirstLetter }}</span
                        >
                      </template>
                    </template>
                    <template v-if="'departments' in job && job.departments.length > 0">
                      <v-icon small class="mr-1">mdi-sitemap-outline</v-icon>
                      <span class="text-caption">
                        {{ job.departments[0] | capitalizeFirstLetter }}
                      </span>
                    </template>
                  </v-card-actions>
                </v-card>
              </template>
            </template>
            <template v-else>
              <div class="text-center w-100">
                <v-progress-circular indeterminate color="primary" />
              </div>
            </template>
          </v-row>
        </v-container>
      </v-row>
      <!-- CARROUSEL MOOD -->
      <v-row class="mx-0 px-0 mb-15" v-if="pageContent.hqSlider && pageContent.hqSlider != ''">
        <v-container class="pt-16" style="max-width: 1400px">
          <liCarrousel :data="JSON.parse(pageContent.hqSlider)" />
        </v-container>
      </v-row>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
import _ from "lodash";
export default {
  title: "",
  name: "HqJobs",
  data() {
    return {
      apiPublicUrl: API_URL,
      filterPlaces: null,
      filterEmployment: null,
      filterDepartment: null,
      filters: [],
    };
  },
  computed: {
    departmentNoOfItems() {
      if (this.isAppMobile) {
        return 1;
      } else if (this.isAppTablet) {
        return 3;
      } else {
        return 4;
      }
    },
    departmentNoOfCols() {
      if (this.isAppMobile) {
        return 12;
      } else if (this.isAppTablet) {
        return 4;
      } else {
        return 3;
      }
    },
    location: {
      get() {
        return "hq";
      },
      set(val) {
        this.$router.push(`/${this.language}/${val}/jobs`, this.loadJobs);
      },
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
    jobs() {
      const jobs = this.$store.getters["liApiStore/getJobs"](this.location);
      if (this.language == "nl" && jobs != null) {
        let cleanJobs = jobs.filter((job) => {
          return !job.employment.includes("stage") && job.jobLanguageCode == "nl" && job.title.toLowerCase() != "open sollicitatie";
        });

        const internShips = jobs.filter((job) => {
          return job.employment.includes("stage");
        });
        if (internShips != undefined) {
          cleanJobs = cleanJobs.concat(internShips);
        }
        const openSollicitatie = jobs.find((job) => {
          return job.jobLanguageCode == "nl" && job.title.toLowerCase() == "open sollicitatie";
        });
        if (openSollicitatie != undefined) {
          cleanJobs = cleanJobs.concat(openSollicitatie);
        }
        return cleanJobs;
      } else {
        return jobs;
      }
      // return this.$store.getters["liApiStore/getJobs"](this.location);
    },
    filteredJobs() {
      if (this.jobs != null) {
        if (this.filters.length > 0) {
          return this.arrayFilter(this.jobs, this.filters, "AND");
        } else {
          return this.jobs;
        }
      } else {
        return this.jobs;
      }
    },
    places() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.address != null && job.address != undefined && job.address != "" && job.address.city != "") {
            if (!filterItems.some((item) => item.line === job.address.line)) {
              filterItems.push(job.address);
            }
          }
        });
        return filterItems.sort((a, b) => (a.city > b.city ? 1 : a.city === b.city ? (a.size > b.size ? 1 : -1) : -1));
      } else {
        return null;
      }
    },
    employments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.employment != null && job.employment != undefined && job.employment != "") {
            if (job.employment.length > 1) {
              job.employment.forEach((employment) => {
                if (!filterItems.includes(employment)) {
                  filterItems.push(this.capitalizeFirstLetter(employment));
                }
              });
            } else {
              if (!filterItems.includes(job.employment[0])) {
                filterItems.push(this.capitalizeFirstLetter(job.employment[0]));
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
    departments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.departments != null && job.departments != undefined && job.departments != "") {
            if (job.departments.length > 1) {
              job.departments.forEach((item) => {
                if (!filterItems.includes(item)) {
                  filterItems.push(item);
                }
              });
            } else {
              if (!filterItems.includes(job.departments[0])) {
                filterItems.push(job.departments[0]);
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
    departmentItems() {
      return this.$store.getters["liApiContent/getCollection"](`departments_${this.language}`);
    },
    arrangedDepartmentItems() {
      if (this.departmentItems != null && this.departments != null && this.jobs != null) {
        this.departmentItems.forEach((department) => {
          let calc = 0;
          this.jobs.forEach((job) => {
            if (job.departments != undefined && job.departments.includes(department.name.trim())) {
              calc++;
            }
          });
          this.$set(department, "amountOfJobs", calc);
        });
        let items = _.orderBy(this.departmentItems, ["amountOfJobs"], ["desc"]);
        return items;
      } else {
        return null;
      }
    },
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
    filterPlaces(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
        this.filters.push({ Field: "address.city", Values: [val.city] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
      }
    },
    filterEmployment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
        this.filters.push({ Field: "employment", Values: [val.toLowerCase()] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
      }
    },
    filterDepartment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
        this.filters.push({ Field: "departments", Values: [val] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
      }
    },
  },
  created() {
    this.fetchURL();
  },
  mounted() {
    // console.log("START -- HQ JOBS VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- HQ JOBS VIEW");
    this.setupPage();
    if (this.departmentItems == null) {
      this.fetchDepartmentItems();
    }
  },
  methods: {
    goTo(department) {
      this.$router.push(department.fullurl);
    },
    totalRows(collection, itemsPerSlide) {
      return Math.ceil(collection.length / itemsPerSlide);
    },
    changeRow(collection, row, itemsPerSlide) {
      const totalRows = this.totalRows(collection);
      if (row < 1) row = 1;
      if (row > totalRows) row = totalRows;
      let items = [];
      for (var i = (row - 1) * itemsPerSlide; i < row * itemsPerSlide && i < collection.length; i++) {
        items.push(collection[i]);
      }
      return items;
    },
    setupPage() {
      this.fetchPageData();
      this.loadJobs();
    },
    loadJobs() {
      this.$store.dispatch("liApiStore/GET_JOBS", {
        location: this.location,
        language: this.language,
      });
    },
  },
};
</script>
<style lang="scss">
.liDepartments-carrousel {
  &.theme--light {
    .v-window__prev,
    .v-window__next {
      margin: 0;
      top: calc(50% - 50px);
      .v-btn {
        height: 50px;
        width: 50px;
        background-color: #fff;
        color: #363636 !important;
      }
    }
  }
}
</style>
