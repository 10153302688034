import liApiService from '../services/liApi.service';
export const liApiStore = {
    namespaced: true,
    state: {
        jobs:{
            hq:null,
            retail: null,
            pme_hq:null,
            pme_retail:null
        }
    },
    actions: {
        GET_JOBS({
            commit
        }, _data) {
            // console.log(_data);
            return liApiService
                .getJobs(_data)
                .then(response => {
                        commit('setJobs', {jobs:response,location:_data.location});
                        return Promise.resolve();
                    },
                    error => {
                      console.debug(error);
                    });
        },
        POST_CANDIDATE({
            commit
        }, _data) {
            // console.log(_data);
            return liApiService
                .postCandidate(_data)
                .then(response => {
                        commit('emptyFN');
                        return Promise.resolve(response);
                    },
                    error => {
                      console.debug(error);
                    });
        }
        
    },
    mutations: {
        emptyFN(){
            // SILENCE IS GOLDEN
        },
        setJobs(state, _data){
            state.jobs[_data.location] = _data.jobs;
        }
    },
    getters: {
        retailJobs:(state)=> {
            return state.jobs.retail;
        },
        hqJobs:(state)=> {
            return state.jobs.hq;
        },
        getJobs:(state)=> (location)=>{
            return state.jobs[location];
        },
        getJob: (state) => (location,id)=>{
            if (state.jobs[location] != null){
                if (state.jobs[location] != null){
                    return state.jobs[location].find(item => item.id === id);
                } else {
                    return null;
                }
            } else{
                return null;
            }
        }
    }
};