import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
class LiApiService {
  getJobs(_data) {
    const baseURI = `${API_URL}jobs/${_data.language}/${_data.location}`;
    const body = {};
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    return axios
      .get(baseURI, body, config)
      .then(function (response) {
        // console.log(response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  postCandidate(_data) {
    const baseURI = `${API_URL}candidate`;
    const body = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
}
export default new LiApiService();
