<template>
  <v-row>
    <v-container fluid style="max-width:1200px;" v-if="data.title !='' || data.subtitle !=''">
      <!-- THE THUMBNAILS -->
      <v-col cols="12" class="m-auto">
        <div class="primary--text mt-5 text-center">
          <h1 class="text-md-h2 mb-3 font-weight-bold" v-html="data.title" v-if="data.title !=''"/>
          <hr v-if="data.subtitle !=''"/>
          <div
            class="text-p text-sm-h5 text-center font-weight-light mt-7 mb-7"
            v-html="data.subtitle"
            v-if="data.subtitle !=''"/>
          <hr v-if="data.subtitle !=''"/>
        </div>
      </v-col>
    </v-container>
    <v-container fluid class="pt-0">
      <v-col cols="12" md="8" class="m-auto pt-0 pb-0 px-0">
        <v-tabs v-model="tab" centered class="brandtabs">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="brand in data.slider"
            :key="`tab-${brand.name}`"
            class="brandTab"
            active-class="active"
          >
            <img :src="`${apiPublicUrl}${brand.thumbnailURL}`" width="75" />
          </v-tab>
        </v-tabs>
      </v-col>
      <v-container fluid class="pt-0">
        <v-col cols="12" class="pl-0 pr-0 pt-0">
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="brand in data.slider"
              :key="`item-${brand.name}`"
            >
              <v-img
                :src="`${apiPublicUrl}${brand.fullUrl}`"
                class="d-none d-sm-flex"
                :aspect-ratio="16 / 9"
              >
                <v-card dark class="brandOverlay" :class="brand.titlePosition">
                  <v-card-title class="text-h3 mb-2">
                    <b>{{ brand.title }}</b>
                  </v-card-title>
                  <v-card-subtitle class="text-h6 white--text">
                    {{ brand.subtitle }}
                  </v-card-subtitle>
                </v-card>
              </v-img>
              <v-img
                :src="brand.image"
                min-height="400"
                class="d-flex d-sm-none"
              >
                <v-card dark class="brandOverlay">
                  <v-card-title class="text-h4 mb-2">
                    <b>{{ brand.title }}</b>
                  </v-card-title>
                  <v-card-subtitle class="text-p white--text">
                    {{ brand.subtitle }}
                  </v-card-subtitle>
                </v-card>
              </v-img>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-container>
    </v-container>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "liCarrouselThunmbs",
  data() {
    return {
      apiPublicUrl: API_URL,
      tab: 0,
    };
  },
  props: {
    data: null,
  },
};
</script>
