<template>
  <v-footer class="liFooter" padless v-if="footerContent !=null">
    <v-row justify="center" no-gutters>
      <v-container no-gutters>
        <v-col class="pt-10 pb-5 text-center text-caption white--text" cols="12">
          <!-- DESKTOP -->
          <div class="py-4 d-sm-flex w-100 d-none">
            <a
              :href="footerContent.about.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto"
            >
              {{ footerContent.about.title }}
            </a>
            <a
              :href="footerContent.terms.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto"
            >
              {{ footerContent.terms.title }}
            </a>
            <a
              :href="footerContent.privacy.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto"
            >
              {{ footerContent.privacy.title }}
            </a>
            <a
              :href="footerContent.disclaimer.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto"
            >
              {{ footerContent.disclaimer.title }}
            </a>
          </div>
          <!-- MOBILE -->
          <div class="py-4 d-sm-none w-100 d-block">
            <a
              :href="footerContent.about.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto my-1 d-block"
            >
              {{ footerContent.about.title }}
            </a>
            <a
              :href="footerContent.terms.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto my-1 d-block"
            >
              {{ footerContent.terms.title }}
            </a>
            <a
              :href="footerContent.privacy.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto my-1 d-block"
            >
              {{ footerContent.privacy.title }}
            </a>
            <a
              :href="footerContent.disclaimer.to"
              target="_blank"
              class="white--text font-weight-black text-decoration-none mx-auto my-1 d-block"
            >
              {{ footerContent.disclaimer.title }}
            </a>
          </div>
        </v-col>
        <v-col class="pb-4 text-center text-caption white--text" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>{{ footerContent.text }}</strong>
        </v-col>
      </v-container>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "liFooter",
  data: () => ({}),
  mounted() {
    this.fetchFooterItems();
  },
  watch:{
    language(){
      this.fetchFooterItems();
    }
  },
  computed: {
    footerItems() {
      return this.$store.getters["liApiContent/getCollection"](
        `main_footer_${this.language}`
      );
    },
    footerContent(){
      let result = null;
      if(this.footerItems != null){
        let block = JSON.parse(this.footerItems[0].content)
        if (block.type === "html"){
          result = JSON.parse(block.html);
        }
      } 
      return result;
    }
  },
  methods: {
    fetchFooterItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_static_blocks",
          where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] footer || website [=>] ${this.website}`,
          orderBy: "sequence ASC",
          select: "content,brand",
        },
        collectionName: `main_footer_${this.language}`,
      });
    }
  }
  
};
</script>
