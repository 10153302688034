import { mapState } from "vuex";
import PME_ICON from "../assets/images/icons/pme_icon.png";
import JB_ICON from "../assets/images/icons/jb_icon.png";
import CI_ICON from "../assets/images/icons/ci_icon.png";

// const PUBLIC_WEBSITE = process.env.VUE_APP_WEBSITE;

export default {
  data: () => ({
    markers: {
      pme: PME_ICON,
      jb: JB_ICON,
      ci: CI_ICON,
    },
    brand:"pme",
    isAppMobile: false,
    isAppTablet: false,
    debug: false,
    scrollPosition: 0,
    fullUrl: null,
    mapOptions: {
      panControl: true,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
      fullscreenControl:false,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    },
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
    window.removeEventListener("scroll", this.updateScroll);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapState("liApiStore", ["hqJobs", "retailJobs"]),
    ...mapState("liApiContent", ["website"]),
    language: {
      get() {
        return this.$route.params.language != undefined
          ? `${this.$route.params.language}`
          : "nl";
      },
      set(val) {
        if (this.language != val) {
          this.$router.push(`/${val}`);
        }
      },
    },
    navigationItems() {
      return this.$store.getters["liApiContent/getCollection"](
        `main_navigation_${this.language}`
      );
    },
    navigationContent(){
      let result = null;
      if(this.navigationItems != null){
        let block = JSON.parse(this.navigationItems[0].content)
        if (block.type === "html"){
          result = JSON.parse(block.html);
        }
      } 
      return result;
    },
    privacyStatement() {
      return this.$store.getters["liApiContent/getCollection"](
        `privacy_statement_${this.language}`
      );
    },
    privacyStatementContent() {
      let result = null;
      if (this.privacyStatement != null) {
        let block = JSON.parse(this.privacyStatement[0].content);
        if (block.type === "richText") {
          result = block.richText;
        }
      }
      return result;
    },
    applyForm() {
      return this.$store.getters["liApiContent/getCollection"](
        `apply_form_${this.language}`
      );
    },
    applyFormContent() {
      let result = null;
      if (this.applyForm != null) {
        let block = JSON.parse(this.applyForm[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
    defaultTexts() {
      return this.$store.getters["liApiContent/getCollection"](
        `default_texts_${this.language}`
      );
    },
    defaultTextContent() {
      let result = null;
      if (this.defaultTexts != null) {
        let block = JSON.parse(this.defaultTexts[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
    ourBrandsBlock() {
      return this.$store.getters["liApiContent/getCollection"](
        `block_pme_brand_${this.language}`
      );
    },
    ourBrandsBlockContent() {
      let result = null;
      if (this.ourBrandsBlock != null) {
        let block = JSON.parse(this.ourBrandsBlock[0].content);
        if (block.type === "html") {
          result = JSON.parse(block.html);
        }
      }
      return result;
    },
    cultureTitleBlock(){
      return this.$store.getters["liApiContent/getCollection"](
        `culture_title_${this.language}`
      );
    },
    cultureTitleBlockContent() {
      let result = null;
      if (this.cultureTitleBlock != null) {
        result = JSON.parse(this.cultureTitleBlock[0].content);
      }
      return result;
    },
    hqJobDetailHeaderImage(){
      return this.$store.getters["liApiContent/getCollection"](
        `hq_job_header_image_${this.language}`
      );
    },
    hqJobDetailHeaderImageContent() {
      let result = null;
      if (this.hqJobDetailHeaderImage != null) {
        result = JSON.parse(this.hqJobDetailHeaderImage[0].content);
      }
      return result;
    },
    hqJobDetailMoodImage(){
      return this.$store.getters["liApiContent/getCollection"](
        `hq_job_mood_image_${this.language}`
      );
    },
    hqJobDetailMoodImageContent() {
      let result = null;
      if (this.hqJobDetailMoodImage != null) {
        result = JSON.parse(this.hqJobDetailMoodImage[0].content);
      }
      return result;
    },
    storeItems() {
      return this.$store.getters["liApiContent/getCollection"](
        `stores_${this.language}`
      );
    },
    departmentItems() {
      return this.$store.getters["liApiContent/getCollection"](
        `departments_${this.language}`
      );
    },
    cultureItems() {
      return this.$store.getters["liApiContent/getCollection"](
        `culture_${this.language}`
      );
    },
  },
  methods: {
    findStore(job){
      if (
        job &&
        job.address &&
        job.address.line &&
        job.address.line != '' &&
        this.storeItems != null
      ) {
        let store = this.storeItems.find(
          (store) =>
            store.fullurl.trim() ==
            `/${this.language}/retail/location/${encodeURIComponent(
              job.address.line
            )}`
        );
        if (store != undefined) {
          if (typeof store.content == "string") {
            let content = JSON.parse(store.content);
            this.$set(store, "content", content);
          }
          return store;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    findStoreBrand(job){
      if (
        job &&
        job.address &&
        job.address.line &&
        job.address.line != '' &&
        this.storeItems != null
      ) {
        let store = this.storeItems.find(
          (store) =>
            store.fullurl.trim() ==
            `/${this.language}/retail/location/${encodeURIComponent(
              job.address.line
            )}`
        );
        if (store != undefined) {
          if (typeof store.content == "string") {
            let content = JSON.parse(store.content);
            this.$set(store, "content", content);
          }
          return store.brand;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    share(location){
      let link = '';
      let url = window.location.href;
      if (location == 'fb'){
        link = `https://www.facebook.com/sharer.php?u=${url}`;
      } 
      if (location == 'tw'){
        link = `https://twitter.com/intent/tweet?url=${url}`;
      } 
      if (location == 'li'){
        link = `https://www.linkedin.com/cws/share/?url=${url}`;
      } 
      window.open(link);
      
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    fetchURL() {
      this.fullUrl = this.$route.path == "/" ? "/nl" : this.$route.path;
    },
    fetchNavigationItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_static_blocks",
          where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] main_navigation || website [=>] ${this.website}`,
          orderBy: "sequence ASC",
          select: "content,brand",
        },
        collectionName: `main_navigation_${this.language}`,
      });
    },
    fetchDepartmentData() {
      // where: `status [=>] 1 || language [=>] ${this.language} || website [=>] ${this.website} || fullurl [=>] ${this.fullUrl}`,
      this.$store.dispatch("liApiContent/GET_PAGE", {
        object: "li_departments",
        where: `status [=>] 1 || language [=>] ${this.language} || website [=>] justbrands.eu || fullurl [=>] ${this.fullUrl}`,
        orderBy: "name ASC",
        select: "pagetitle,pagedescription,content,language,brand,fullurl,name",
      });
    },
    fetchPageData() {
      this.$store.dispatch("liApiContent/GET_PAGE", {
        object: "li_pages",
        where: `status [=>] 1 || language [=>] ${this.language} || website [=>] ${this.website} || fullurl [=>] ${this.fullUrl}`,
        orderBy: "name ASC",
        select: "pagetitle,pagedescription,content,language,brand,fullurl,name",
      });
    },
    fetchCultureItems() {
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_culture_items",
          where: `status [=>] 1 || language [=>] ${this.language}`,
          orderBy: "sequence ASC",
          select: "text,image,name,link,link_label",
        },
        collectionName: `culture_${this.language}`,
      });
    },
    fetchDepartmentItems() {
      // where: `status [=>] 1 || language [=>] ${this.language} || website [=>] ${this.website}`,
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_departments",
          where: `status [=>] 1 || language [=>] ${this.language} || website [=>] justbrands.eu`,
          orderBy: "sequence ASC",
          select:
            "name,header_image,language,content,brand,card_image,card_title,pagetitle,pagedescription,identifier,fullurl",
        },
        collectionName: `departments_${this.language}`,
      });
    },
    fetchStoreItems() {
      // where: `li_retail_stores.status [=>] 1 || li_retail_stores.language [=>] ${this.language} || li_retail_stores.website [=>] ${this.website}`,
      this.$store.dispatch("liApiContent/GET_COLLECTION", {
        request: {
          object: "li_retail_stores",
          orderBy: "li_retail_stores.sequence ASC",
          where: `li_retail_stores.status [=>] 1 || li_retail_stores.language [=>] ${this.language} || li_retail_stores.website [=>] justbrands.eu || li_retail_stores.brand [=>] pme`,
          select: "*",
          resolveForeign: {key:'foreign_store',foreign_key:'customer_number',foreign_object:'li_stores_2023'}
        },
        collectionName: `stores_${this.language}`,
      });
    },
    fetchAppDefaultTexts() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] default_texts || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `default_texts_${this.language}`,
        });
      }
    },
    fetchAppApplyForm() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] apply_form || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `apply_form_${this.language}`,
        });
      }
    },
    fetchAppPrivacyStatement() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] privacy_statement || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `privacy_statement_${this.language}`,
        });
      }
    },
    fetchAppOurBrands() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] block_pme_brand || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `block_pme_brand_${this.language}`,
        });
      }
    },
    fetchAppCultureTitle() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] culture_title || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `culture_title_${this.language}`,
        });
      }
    },
    fetchHqJobHeaderImage() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] hq_job_detail_header_image || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `hq_job_header_image_${this.language}`,
        });
      }
    },
    fetchHqJobMoodImage() {
      if (this.language != null) {
        this.$store.dispatch("liApiContent/GET_COLLECTION", {
          request: {
            object: "li_static_blocks",
            where: `status [=>] 1 || language [=>] ${this.language} || block_identifier [=>] hq_job_detail_mood_image || website [=>] ${this.website}`,
            orderBy: "sequence ASC",
            select: "content,brand",
          },
          collectionName: `hq_job_mood_image_${this.language}`,
        });
      }
    },
    getRandomFromArray(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    /**
     * [!] USE WITH CAUTION [!]
     * Returns a deep find of a key from a dot notation string
     * @param {obj} Object the object where to look in for the key
     * @param {is} String the  "key.key.key" notation for the key to look for in the given object
     *
     */
    // obj,'1.2.3' -> multiIndex(obj,['1','2','3'])
    pathIndex(obj, is) {
      return this.multiIndex(obj, is.split("."));
    },
    // obj,['1','2','3'] -> ((obj['1'])['2'])['3']
    multiIndex(obj, is) {
      return is.length ? this.multiIndex(obj[is[0]], is.slice(1)) : obj;
    },
    triggerHome() {
      if (this.$route.name != "Home") {
        this.$router.push("/");
      }
    },
    stripTags(value) {
      if (!value) return "";
      value = value.toString();
      return value.replace(/(<([^>]+)>)/gi, "");
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    onResize() {
      this.isAppMobile = window.innerWidth < 600;
      this.isAppTablet = window.innerWidth > 600 && window.innerWidth < 1500;
    },
    matchesOR(_item, _criteria) {
      for (var n = 0; n < _criteria.length; n++) {
        if (_criteria[n]["Values"].indexOf(_item[_criteria[n]["Field"]]) > -1) {
          return true;
        }
      }
    },
    matchesAND(_item, _criteria) {
      let count = 0;
      for (var n = 0; n < _criteria.length; n++) {
        if (_criteria[n]["Field"].includes(".")) {
          // MULTI LEVEL INDEX SEARCH -> should only return a string ..... SHOUD
          const value = this.pathIndex(_item, _criteria[n]["Field"]);
          if (typeof value === "string") {
            if (_criteria[n]["Values"].includes(value)) {
              count++;
            }
          }
        } else {
          // value is string
          if (typeof _item[_criteria[n]["Field"]] === "string") {
            if (
              _criteria[n]["Values"].indexOf(_item[_criteria[n]["Field"]]) > -1
            ) {
              count++;
            }
          }
          // value is object
          if (typeof _item[_criteria[n]["Field"]] === "object") {
            const found = _item[_criteria[n]["Field"]].some((r) =>
              _criteria[n]["Values"].includes(r)
            );
            if (found) {
              count++;
            }
          }
        }
      }
      return count == _criteria.length;
    },
    arrayFilter(_data, _criteria, _type) {
      let matches = [];
      for (var i = 0; i < _data.length; i++) {
        // Determine if the current item matches the filter criteria
        if (_type == "OR") {
          if (this.matchesOR(_data[i], _criteria)) {
            matches.push(_data[i]);
          }
        } else if (_type == "AND") {
          if (this.matchesAND(_data[i], _criteria)) {
            matches.push(_data[i]);
          }
        }
      }
      return matches;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deCapitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
  },
};
