<template>
  <v-container class="w-100 px-0" fluid>
    <template v-if="pageContent != null && defaultTextContent != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img :src="`${apiPublicUrl}${pageContent.image}`" min-height="400px" max-height="400px" class="header blur">
          <v-row class="fill-height mt-10" align="center" justify="center">
            <div class="white--text text-center">
              <h1 class="text-lg-h2 text-sm-h4 text-h6 text-no-break liHeroHeading mb-3" v-html="pageContent.headerTitle" />
              <div class="text-sm-h5 text-p white--text text-uppercase text-center li-text-shadow" v-html="pageContent.headerSubTitle" />
            </div>
          </v-row>
        </v-img>
      </v-row>
      <!-- WE ARE -->
      <v-row class="mx-0 px-0">
        <v-container class="pt-16" style="max-width: 1400px">
          <v-row>
            <v-col cols="12" md="6" lg="4" class="d-flex">
              <div class="my-auto text-black">
                <h2 class="text-md-h4 text-h4 mb-3 liHeroHeading" v-if="pageContent.introTitle && pageContent.introTitle != ''" v-text="pageContent.introTitle.replace(/(<([^>]+)>)/gi, '')" />
                <div v-if="pageContent.introText && pageContent.introText != ''" class="text-p mt-7 mb-7 mx-auto" style="max-width: 1000px" v-html="pageContent.introText" />
              </div>
            </v-col>
            <!-- VIDEO -->
            <template v-if="pageContent.videoCode && pageContent.videoCode != ''">
              <v-col cols="12" md="6" lg="8">
                <div class="bg-white pa-3 elevation-10 rounded-10">
                  <liVimeoVideo :vimeoId="pageContent.videoCode" />
                </div>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-row>
      <!-- MAP -->
      <v-row class="mx-0 px-0" v-if="storeItems != null">
        <v-container class="pt-16" style="max-width: 1900px">
          <div style="position: relative">
            <!-- OVERLAY -->
            <template v-if="storeOverlay != null">
              <v-sheet class="liStoreOverlay" elevation="4">
                <!-- @click="goTo(storeOverlay)" -->
                <v-card class="mx-0" elevation="0">
                  <!-- CLOSE STORE OVERLAY -->
                  <v-btn elevation="0" absolute small dark fab class="text-center mx-1 rounded" @click="closeStore" style="z-index: 10; background: transparent; top: 5px; right: 5px">
                    <v-icon color="dark"> mdi-close </v-icon>
                  </v-btn>
                  <!-- STORE IMAGE -->
                  <v-img :src="`${apiPublicUrl}${storeOverlay.card_image}`" :height="200"> </v-img>
                  <!-- JOBS COUNTER -->
                  <div style="position: relative" class="text-p py-4 px-4">
                    <v-btn absolute class="text-white bg-primary" fab right top @click="goTo(storeOverlay)">
                      <v-icon>mdi-arrow-right</v-icon>
                      <!-- {{ storeOverlay.amountOfJobs }} -->
                    </v-btn>
                    <!-- STORE NAME -->
                    <div class="text-truncate">
                      <span class="font-weight-bold text-h5"> {{ storeOverlay.card_title }} </span><br />
                      <span class="store caption">
                        <template v-if="storeOverlay.brand == 'pme'"> PME Legend Store </template>
                        <template v-else-if="storeOverlay.brand == 'jb'"> Just Brands Store </template>
                        <template v-else-if="storeOverlay.brand == 'ci'"> Cast Iron Store </template>
                        <template v-else> &nbsp; </template>
                      </span>
                    </div>
                  </div>

                  <!-- 
                        <pre>
                        {{storeOverlay.city}}
                        {{storeOverlay.number}} 
                        {{storeOverlay.postal}}
                    </pre>
                    {{storeOverlay.fullurl}}  
                  -->
                    
                  <hr />

                  <div class="liJobsContainer px-2 py-2 grey lighten-4">
                    <perfect-scrollbar>
                      <!-- JOBS -->
                      <template v-if="storeOverlayJobsList != null">
                        <template v-if="storeOverlayJobsList.length === 0">
                          <!-- NO JOB -->
                          <h4 class="font-weight-bold">
                            {{ defaultTextContent.noJobsFound }}
                          </h4>
                          <p class="caption">
                            {{ defaultTextContent.noJobsFoundTextMap }}
                          </p>
                        </template>
                        <template v-else>
                          <v-card elevation="0" v-for="job in storeOverlayJobsList" :key="job.id" class="job my-2" :to="`/${language}/${location}/job/${job.id}`">
                            <v-btn absolute color="white" elevation="0" right top small style="top: 50%; transform: translateY(-50%); margin: 0; padding: 0; min-width: unset; min-width: 20px">
                              <v-icon>mdi-arrow-right</v-icon>
                              <!-- {{ storeOverlay.amountOfJobs }} -->
                            </v-btn>
                            <v-card-text class="text-no-break font-weight-bold pb-0 pr-0" style="color: rgba(0, 0, 0, 0.87); max-width:calc(100% - 40px);" v-text="job.title"> </v-card-text>
                            <!-- 
                                {{job.address.city}}
                              {{job.address.number}}
                              {{job.address.zipcode}} 
                              -->
                            <v-card-actions class="pl-4">
                              <template v-if="'hours' in job && job.hours != ''">
                                <v-icon small class="mr-1">mdi-clock-time-eight-outline</v-icon>
                                <span class="text-caption" :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-4'"
                                  >{{ job.hours }}
                                  <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                                  {{ defaultTextContent.hour }}</span
                                >
                                <template v-if="'employment' in job && job.employment != ''">
                                  <span class="text-caption mr-4">
                                    |
                                    {{ job.employment[0] | capitalizeFirstLetter }}</span
                                  >
                                </template>
                              </template>
                              <template v-if="$route.params.location === 'hq' && 'departments' in job && job.departments.length > 0">
                                <v-icon small class="mr-1">mdi-sitemap-outline</v-icon>
                                <span class="text-caption">
                                  {{ job.departments[0] | capitalizeFirstLetter }}
                                </span>
                              </template>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </template>
                      <template v-else>
                        <div class="text-center w-100">
                          <v-progress-circular indeterminate color="primary" />
                        </div>
                      </template>
                      <!-- 
                      <pre>
                        {{ storeOverlay }}
                      </pre> 
                      -->
                    </perfect-scrollbar>
                  </div>
                </v-card>
              </v-sheet>
            </template>
            <!-- GOOGLE MAP -->
            <gmap-map v-if="mapCenter != null && mapZoom != null" :center="mapCenter" :zoom="mapZoom" :options="mapOptions" ref="jobsMap" map-type-id="terrain" style="width: 100%; height: 600px">
              <gmap-marker :key="index" v-for="(m, index) in storeItems" :position="{ lat: Number(m.g_lat), lng: Number(m.g_long) }" :clickable="true" @click="openStore(m)" :icon="markers[m.brand]" />
            </gmap-map>
          </div>
        </v-container>
      </v-row>
      <!-- END MAP -->
      <!-- STORES CARROUSEL -->
      <v-row class="mx-0 px-0 mb-0 mt-15">
        <v-container style="max-width: 1900px">
          <!-- <pre>

            {{ arrangedStoretItems }}
          </pre> -->
          <v-carousel
            class="liStores-carrousel"
            delimiter-icon="mdi-minus"
            dark
            next-icon=" "
            prev-icon=" "
            hide-delimiter-background
            hide-delimiters
            :show-arrows="true"
            :continuous="false"
            :height="isAppMobile ? 350 : 350"
            light
            v-if="arrangedStoretItems != null && arrangedStoretItems.length > 0"
          >
            <v-carousel-item :key="index" v-for="(row, index) in totalRows(arrangedStoretItems, storeNoOfItems)">
              <v-row style="max-width: calc(100% - 110px); margin: auto">
                <v-col :cols="storeNoOfCols" :key="store.id" v-for="store in changeRow(arrangedStoretItems, row, storeNoOfItems)">
                  <v-card class="mx-0" @click="goTo(store)">
                    <v-img :src="`${apiPublicUrl}${store.card_image}`" :height="200"> </v-img>
                    <div style="position: relative" class="text-p py-5 px-5">
                      <v-btn absolute class="text-white bg-primary" fab small right top>
                        {{ store.amountOfJobs }}
                      </v-btn>
                      <div class="text-truncate">
                        <span class="text-uppercase text-black liTitle"> {{ store.card_title }}</span
                        ><br />
                        <!--
                          -->
                        <!-- {{ store.city }}<br />
                          {{ store.number }}<br />
                          {{ store.postal }}<br /> -->
                        <!-- {{ store.fullurl }}<br /> -->
                        <span class="store caption text-truncate text-uppercase text-black">
                          <template v-if="store.brand == 'pme'"> PME Legend Store </template>
                          <template v-else-if="store.brand == 'jb'"> Just Brands Store </template>
                          <template v-else-if="store.brand == 'ci'"> Cast Iron Store </template>
                          <template v-else> &nbsp; </template>
                        </span>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <!-- {{department.card_title}} -->
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-container>
      </v-row>
      <!-- HEADING JOBS -->
      <h3 class="text-h3 text-md-h3 mb-15 font-weight-bold text-uppercase text-center text-black" v-html="pageContent.jobsTitle" />
      <!-- FILTERS-->
      <v-row justify="center" class="bg-light liFilterRow mx-0 px-0 mb-5 mt-15" v-if="(places != null && places.length > 0) || (employments != null && employments.length > 0)">
        <template v-if="places != null && places.length > 0">
          <v-col cols="12" sm="4" md="3" class="py-0 mt-2">
            <v-select
              v-model="filterPlaces"
              :label="defaultTextContent.filterLocation"
              :items="places"
              prepend-icon="mdi-map-marker"
              dense
              class="pa-0"
              item-text="city"
              item-value="city"
              persistent-hint
              return-object
              single-line
              clearable
            ></v-select>
          </v-col>
        </template>

        <template v-if="employments != null && employments.length > 0">
          <v-col cols="12" sm="4" md="3" class="py-0 mt-2">
            <v-select
              dense
              class="pa-0"
              v-model="filterEmployment"
              :label="defaultTextContent.filterEmployment"
              :items="employments"
              prepend-icon="mdi-clock-time-eight-outline"
              single-line
              clearable
            ></v-select>
          </v-col>
        </template>
      </v-row>
      <!-- JOBS -->
      <v-row class="bg-light px-sm-10 py-10 jobs">
        <!-- JOBS -->
        <template v-if="filteredJobs != null">
          <template v-if="filteredJobs.length === 0">
            <!-- NO JOB -->
            <v-card elevation="0" tile class="job px-5 py-5" style="display: flex">
              {{ defaultTextContent.noJobsFound }}
            </v-card>
          </template>
          <template v-else>
            <v-card elevation="0" tile v-for="job in filteredJobs" :key="job.id" class="job d-flex flex-column" :to="`/${language}/${location}/job/${job.id}`">
              <v-card-title class="text-no-break font-weight-bold text-black text-uppercase liTitle" v-text="job.title"></v-card-title>
              <v-card-subtitle class="mt-1 text-no-break text-black" v-html="job.summary"></v-card-subtitle>
              <v-spacer></v-spacer>

              <!-- <liLogos :brand="findStoreBrand(job)" :tags="job.tags" /> -->
              <!-- 
                {{job.address.line}}<br>
                {{job.address.city}}
                {{job.address.street}}
                {{job.address.number}}
                {{job.address.zipcode}}  
                          {{encodeURIComponent(job.address.line)}}
                          -->

              <v-divider v-if="job.address.city != '' || ('hours' in job && job.hours != '')" />

              <v-card-actions>
                <template v-if="job.address.city != ''">
                  <v-icon small class="mr-1">mdi-map-marker</v-icon>
                  <span class="text-caption mr-4">{{ job.address.city }}</span>
                </template>
                <template v-if="'hours' in job && job.hours != ''">
                  <v-icon small class="mr-1">mdi-clock-time-eight-outline</v-icon>
                  <span class="text-caption" :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-4'"
                    >{{ job.hours }}
                    <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                    {{ defaultTextContent.hour }}</span
                  >
                  <template v-if="'employment' in job && job.employment != ''">
                    <span class="text-caption mr-4">
                      |
                      {{ job.employment[0] | capitalizeFirstLetter }}</span
                    >
                  </template>
                </template>
                <template v-if="$route.params.location === 'hq' && 'departments' in job && job.departments.length > 0">
                  <v-icon small class="mr-1">mdi-sitemap-outline</v-icon>
                  <span class="text-caption">
                    {{ job.departments[0] | capitalizeFirstLetter }}
                  </span>
                </template>
              </v-card-actions>
            </v-card>
          </template>
        </template>
        <template v-else>
          <div class="text-center w-100">
            <v-progress-circular indeterminate color="primary" />
          </div>
        </template>
      </v-row>
      <!-- BRAND -->
      <v-row class="mx-0 px-0" v-if="ourBrandsBlockContent != null">
        <v-container class="pt-16" fluid>
          <liBrandBlock1 :data="ourBrandsBlockContent" />
        </v-container>
      </v-row>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
import PME_ICON from "../assets/images/icons/pme_icon.png";
import JB_ICON from "../assets/images/icons/jb_icon.png";
import CI_ICON from "../assets/images/icons/ci_icon.png";
import _ from "lodash";
export default {
  title: "",
  name: "Jobs",
  data() {
    return {
      mapCenterDefault: {
        de: { lat: 51.1543787, lng: 7.2123835 },
        nl: { lat: 52.2076832, lng: 4.1585844 },
        be: { lat: 50.4956764, lng: 3.3453107 },
      },
      mapCenter: null,
      mapZoomDefault: {
        de: 7,
        nl: 7,
        be: 8,
      },
      mapZoomedIn: 13,
      mapZoom: null,
      markers: {
        pme: PME_ICON,
        jb: JB_ICON,
        ci: CI_ICON,
      },
      storeOverlay: null,
      storeOverlayJobsList: null,
      pmeIcon: PME_ICON,
      jbIcon: JB_ICON,
      ciIcon: CI_ICON,
      apiPublicUrl: API_URL,
      filterPlaces: null,
      filterEmployment: null,
      filterDepartment: null,
      filters: [],
    };
  },
  computed: {
    storeNoOfItems() {
      if (this.isAppMobile) {
        return 1;
      } else if (this.isAppTablet) {
        return 3;
      } else {
        return 4;
      }
    },
    storeNoOfCols() {
      if (this.isAppMobile) {
        return 12;
      } else if (this.isAppTablet) {
        return 4;
      } else {
        return 3;
      }
    },
    arrangedStoretItems() {
      if (this.storeItems != null && this.places != null && this.jobs != null) {
        this.storeItems.forEach((store) => {
          let calc = 0;
          this.jobs.forEach((job) => {
            // if(job.address.city == "Ochtrup"){
            //   console.log(`/${this.language}/retail/location/${encodeURIComponent(job.address.line)}`, store.fullurl);
            // }

            // console.log(`/${this.language}/retail/location/${encodeURIComponent(
            //     'Spuistraat 16, 2511 BD Den Haag, The Netherlands'
            //   )}`);

            if (store.fullurl.trim() == `/${this.language}/retail/location/${encodeURIComponent(job.address.line)}`) {
              calc++;
            }
          });
          this.$set(store, "amountOfJobs", calc);
        });
        let items = _.orderBy(this.storeItems, ["amountOfJobs"], ["desc"]);
        return items;
      } else {
        return null;
      }
    },
    location: {
      get() {
        return "retail";
      },
      set(val) {
        this.$router.push(`/${this.language}/${val}/jobs`, this.loadJobs);
      },
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
    jobs() {
      // const jobs = this.$store.getters["liApiStore/getJobs"](this.location);
      // if (this.language == "nl") {
      //   let cleanJobs = jobs.filter((job) => {
      //     return !job.employment.includes("stage") && job.jobLanguageCode == "nl" && job.title.toLowerCase() != "open sollicitatie";
      //   });
      //   const internShips = jobs.filter((job) => {
      //     return job.employment.includes("stage");
      //   });
      //   const openSollicitatie = jobs.find((job) => {
      //     return job.jobLanguageCode == "nl" && job.title.toLowerCase() == "open sollicitatie";
      //   });
      //   cleanJobs = cleanJobs.concat(internShips);
      //   cleanJobs = cleanJobs.concat(openSollicitatie);
      //   return cleanJobs;
      // }
      // return jobs;
      const jobs = this.$store.getters["liApiStore/getJobs"](this.location);
      if (this.language == "nl" && jobs != null) {
        let cleanJobs = [];
        const storeManager = jobs.filter((job) => {
          return job.tags.includes("Store Manager");
        });
        const assistantStoreManager = jobs.filter((job) => {
          return job.tags.includes("Assistant Store Manager");
        });
        const visualMerchandiser = jobs.filter((job) => {
          return job.tags.includes("Visual Merchandiser");
        });
        const logistiekMedewerker = jobs.filter((job) => {
          return job.tags.includes("Logistiek Medewerker");
        });
        const verkoopmedewerker38 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 38");
        });
        const verkoopmedewerker32 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 32");
        });
        const verkoopmedewerker24_38 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 24-38");
        });
        const verkoopmedewerker24 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 24");
        });
        const verkoopmedewerker16_24 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 16-24");
        });
        const verkoopmedewerker8_12 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 8-12");
        });
        const verkoopmedewerker3_16 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 3-16");
        });
        const verkoopmedewerker3_12 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 3-12");
        });
        const verkoopmedewerker3_8 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 3-8");
        });
        const verkoopmedewerker8 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 8");
        });
        const verkoopmedewerker3 = jobs.filter((job) => {
          return job.tags.includes("Verkoopmedewerker 3");
        });
        const stage = jobs.filter((job) => {
          return job.tags.includes("Stage");
        });
        const openSollicitatie = jobs.filter((job) => {
          return job.tags.includes("Open Sollicitatie");
        });
       
        cleanJobs = cleanJobs.concat(storeManager);
        cleanJobs = cleanJobs.concat(assistantStoreManager);
        cleanJobs = cleanJobs.concat(visualMerchandiser);
        cleanJobs = cleanJobs.concat(logistiekMedewerker);
        cleanJobs = cleanJobs.concat(verkoopmedewerker38);
        cleanJobs = cleanJobs.concat(verkoopmedewerker32);
        cleanJobs = cleanJobs.concat(verkoopmedewerker24_38);
        cleanJobs = cleanJobs.concat(verkoopmedewerker24);
        cleanJobs = cleanJobs.concat(verkoopmedewerker16_24);
        cleanJobs = cleanJobs.concat(verkoopmedewerker8_12);
        cleanJobs = cleanJobs.concat(verkoopmedewerker8);
        cleanJobs = cleanJobs.concat(verkoopmedewerker3_16);
        cleanJobs = cleanJobs.concat(verkoopmedewerker3_12);
        cleanJobs = cleanJobs.concat(verkoopmedewerker3_8);
        cleanJobs = cleanJobs.concat(verkoopmedewerker3);
        cleanJobs = cleanJobs.concat(stage);
        cleanJobs = cleanJobs.concat(openSollicitatie);
        return cleanJobs;
      } else {
        return jobs;
      }
    },
    filteredJobs() {
      if (this.jobs != null) {
        if (this.filters.length > 0) {
          return this.arrayFilter(this.jobs, this.filters, "AND");
        } else {
          return this.jobs;
        }
      } else {
        return this.jobs;
      }
    },
    places() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.address != null && job.address != undefined && job.address != "" && job.address.city != "") {
            if (!filterItems.some((item) => item.line === job.address.line)) {
              filterItems.push(job.address);
            }
          }
        });
        return filterItems.sort((a, b) => (a.city > b.city ? 1 : a.city === b.city ? (a.size > b.size ? 1 : -1) : -1));
      } else {
        return null;
      }
    },
    employments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.employment != null && job.employment != undefined && job.employment != "") {
            if (job.employment.length > 1) {
              job.employment.forEach((employment) => {
                if (!filterItems.includes(employment)) {
                  filterItems.push(this.capitalizeFirstLetter(employment));
                }
              });
            } else {
              if (!filterItems.includes(job.employment[0])) {
                filterItems.push(this.capitalizeFirstLetter(job.employment[0]));
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
    departments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.departments != null && job.departments != undefined && job.departments != "") {
            if (job.departments.length > 1) {
              job.departments.forEach((item) => {
                if (!filterItems.includes(item)) {
                  filterItems.push(item);
                }
              });
            } else {
              if (!filterItems.includes(job.departments[0])) {
                filterItems.push(job.departments[0]);
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
    filterPlaces(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
        this.filters.push({ Field: "address.city", Values: [val.city] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
      }
    },
    filterEmployment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
        this.filters.push({ Field: "employment", Values: [val.toLowerCase()] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
      }
    },
    filterDepartment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
        this.filters.push({ Field: "departments", Values: [val] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
      }
    },
    storeItems(val) {
      if (val.length > 0 && this.$refs.jobsMap != undefined) {
        this.$refs.jobsMap.$mapPromise.then((map) => {
          //eslint-disable-next-line
          const bounds = new google.maps.LatLngBounds();
          for (let store of val) {
            bounds.extend({
              lat: parseFloat(store.g_lat),
              lng: parseFloat(store.g_long),
            });
          }
          map.fitBounds(bounds);
        });
      }
    },
  },
  created() {
    this.fetchURL();
  },
  mounted() {
    // console.log("START -- HQ JOBS VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- HQ JOBS VIEW");
    this.mapCenter = _.cloneDeep(this.mapCenterDefault[this.language]);
    this.mapZoom = _.cloneDeep(this.mapZoomDefault[this.language]);
    this.setupPage();
    if (this.storeItems == null) {
      this.fetchStoreItems();
      // if (this.$refs.jobsMap) {
      //   console.log(this.$refs.jobsMap);
      // }
    }
  },
  methods: {
    openStore(store) {
      this.storeOverlay = store;
      this.mapCenter = { lat: Number(store.g_lat), lng: Number(store.g_long) };
      this.mapZoom = this.mapZoomedIn;
      this.storeOverlayJobsList = _.cloneDeep(this.jobs);
      let srs_shop_id = store.srs_shop_id;
      let city = store.city;
      let postal = store.postal;
      let number = store.number;
      // console.log(city)
      // this.storeOverlayJobsList.forEach((job)=> {
      //   console.log(job.address.city)
      // //   // if (job.address.line == ' ' ){console.log(`no address`,job.title)};
      // //     console.log(`/${this.language}/retail/location/${encodeURIComponent(job.address.line)}`, store.fullurl)
      // //   // if(`/${this.language}/retail/location/${encodeURIComponent(job.address.line)}`== store.fullurl){
      // //   //   console.log(job.title)

      // //   // }

      // })
       if(srs_shop_id != ""){
        this.storeOverlayJobsList = this.storeOverlayJobsList.filter((job) => job.branchRef == srs_shop_id);
      } else {
        this.storeOverlayJobsList = this.storeOverlayJobsList.filter((job) => job.address.city == city && job.address.zipcode == postal && job.address.number == number);

      }
        
      // this.storeOverlayJobsList = this.storeOverlayJobsList.filter(
      //   (job) => `/${this.language}/retail/location/${encodeURIComponent(job.address.line)}` == store.fullurl
      // );
    },
    closeStore() {
      this.storeOverlay = null;
      this.mapCenter = this.mapCenterDefault[this.language];
      this.mapZoom = this.mapZoomDefault[this.language];
      this.storeOverlayJobsList = null;
    },
    goTo(store) {
      this.$router.push(store.fullurl);
    },
    totalRows(collection, itemsPerSlide) {
      return Math.ceil(collection.length / itemsPerSlide);
    },
    changeRow(collection, row, itemsPerSlide) {
      const totalRows = this.totalRows(collection);
      if (row < 1) row = 1;
      if (row > totalRows) row = totalRows;
      let items = [];
      for (var i = (row - 1) * itemsPerSlide; i < row * itemsPerSlide && i < collection.length; i++) {
        items.push(collection[i]);
      }
      return items;
    },
    loadJobs() {
      this.$store
        .dispatch("liApiStore/GET_JOBS", {
          location: this.location,
          language: this.language,
        })
        .then(() => {});
    },
    setupPage() {
      this.fetchPageData();
      this.loadJobs();
    },
  },
};
</script>
<style lang="scss">
.liJobsContainer {
  .ps {
    height: 270px;
  }
}

.liStoreOverlay {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  overflow: hidden;
  height: calc(100% - 30px);
}
@media (max-device-width: 600px) {
  .liStoreOverlay {
    width: 300px;
  }
}
@media (min-device-width: 600px) and (max-device-width: 960px) {
  .liStoreOverlay {
    width: 400px;
  }
}
@media (min-device-width: 960px) and (max-device-width: 1264px) {
  .liStoreOverlay {
    width: 500px;
  }
}
@media (min-device-width: 1264px) and (max-device-width: 1904px) {
  .liStoreOverlay {
    width: 500px;
  }
}
@media (min-device-width: 1904px) {
  .liStoreOverlay {
    width: 500px;
  }
}
.liStores-carrousel {
  &.theme--light {
    .v-window__prev,
    .v-window__next {
      margin: 0;
      top: calc(50% - 50px);
      .v-btn {
        height: 50px;
        width: 50px;
        background-color: #fff;
        color: #363636 !important;
      }
    }
  }
}
</style>
