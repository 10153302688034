<template>
  <div>
    <v-row
      v-for="(item, index) in getRandomFromArray(cultureItems, itemsToShow)"
      :key="index"
      class="mt-5 mt-sm-0 mx-5 mx-sm-0 my-10"
    >
      <template v-if="index % 2 == 0">
        <v-col cols="12" md="8" class="pa-0">
          <v-sheet
            class="py-5 px-5 py-md-15 px-md-15 d-flex  text-black"
            height="100%"
          >
            <div class="align-self-center">
              <h5 class="text-h5 mb-3 font-weight-bold" v-html="item.name" />
              <div>
                <span v-html="item.text" />
                <v-btn
                  v-if="item.link != ''"
                  :href="item.link"
                  target="_blank"
                  class="btn-cta"
                >
                  {{ item.link_label }}
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4" class="pa-0">
          <v-img :src="`${apiPublicUrl}${item.image}`" />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="8" class="order-md-1 pa-0" >
          <v-sheet
            class="py-5 px-5 py-md-15 px-md-15 d-flex  text-black"
            height="100%"
          >
            <div class="align-self-center">
              <h5 class="text-h5 mb-3 font-weight-bold" v-html="item.name" />
              <div>
                <span v-html="item.text" />
                <v-btn
                  v-if="item.link != ''"
                  :href="item.link"
                  target="_blank"
                  class="btn-cta"
                >
                  {{ item.link_label }}
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4" class="order-md-0 pa-0">
          <v-img :src="`${apiPublicUrl}${item.image}`" />
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "liCultureItems",
  data: () => ({
    apiPublicUrl: API_URL
  }),
  props: {
    itemsToShow: {
      default: 3,
      type: Number,
    },
  },
  methods: {},
};
</script>
