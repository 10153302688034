<template>
  <!-- VIDEO-->
     <v-card elevation="0" width="100%" class="m-auto" v-if="vimeoId != ''">
      <v-responsive :aspect-ratio="16/9" width="100%">
          <!-- :src="`https://player.vimeo.com/video/${vimeoId}?badge=0&autopause=0&player_id=0&app_id=58479`" -->
        <iframe
          :src="vimeoId"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen=""
          style="width: 100%; height: 100%" 
        ></iframe>
      </v-responsive>
     </v-card>
</template>
<script>
export default {
  name: "liRetailVideo",
  data: () => ({}),
  props:{
    vimeoId:{
      default:'',
      type: String,
      required: true,
    }
  }
};
</script>
