<template>
  <v-row>
    <v-container fluid class="pt-0">
      <v-col cols="12" class=" pt-0">
        <v-carousel
          :continuous="true"
          :cycle="true"
          :show-arrows="true"
          hide-delimiter-background
          next-icon=" "
          prev-icon=" "
          delimiter-icon="mdi-minus"
          :height="!isAppMobile?'auto' : 500"
          class="li-carrousel"
        >
          <v-carousel-item
            v-for="(image, index) in data"
            :key="index"
          >
            <v-img :src="`${apiPublicUrl}${image.fullUrl}`" :aspect-ratio="!isAppMobile? 16/9: ''"  :min-height="!isAppMobile? '': 500">
              <v-card
                dark
                class="brandOverlay"
                :class="image.titlePosition"
                v-if="image.title != '' || image.subtitle != ''"
              >
                <v-card-title class="text-h4 text-uppercase">
                  <span class="liHeroHeading">
                    <b>{{ image.title }}</b>
                  </span>
                </v-card-title>
                <v-card-subtitle class="white--text">
                  {{ image.subtitle }}
                </v-card-subtitle>
              </v-card>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-container>
  </v-row>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  name: "liCarrouselDefault",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  props:{
      data:null
  }
};
</script>
