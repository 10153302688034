<template>
  <div>
    <template v-if="brand === 'jb' || (page ==='jobDetail' && this.$route.params.location === 'hq' && language === 'nl')">
      <v-divider v-if="page ==''"/>
      <div class="d-flex w-100">
        <v-sheet
          :width="width"
          :height="height"
          class="m-auto"
          style="background-color:transparent;"
        >
          <v-img :src="`/assets/images/home/logo_pmelegend${colour}.svg`" />
        </v-sheet>
        <v-sheet
          :width="width"
          :height="height"
          class="m-auto"
          style="background-color:transparent;"
        >
          <v-img :src="`/assets/images/home/logo_castiron${colour}.svg`" />
        </v-sheet>
        <v-sheet
          :width="width"
          :height="height"
          class="m-auto"
          style="background-color:transparent;"
        >
          <v-img :src="`/assets/images/home/logo_vanguard${colour}.svg`" />
        </v-sheet>
      </div>
    </template>
   
    <!-- PME LEGEND DE & BE -->
    <template v-else-if="(brand == undefined  && language == 'de') || (brand == undefined  && language == 'be')">
      <v-divider v-if="page ==''"/>
      <div class="d-flex w-100">
          <v-sheet
            :width="width"
            :height="height"
            class="m-auto"
            style="background-color:transparent;"
          >
            <v-img :src="`/assets/images/home/logo_pmelegend${colour}.svg`" />
          </v-sheet>
      </div>
    </template>

    <!-- JB HQ DE & BE -->
    <template v-else-if="brand == undefined && page == '' && language == 'nl'">
      <v-divider v-if="page ==''"/>
      <div class="d-flex w-100">
        <v-sheet
          :width="width"
          :height="height"
          class="m-auto"
          style="background-color:transparent;"
        >
          <v-img :src="`/assets/images/home/logo_pmelegend${colour}.svg`" />
        </v-sheet>
        <v-sheet
          :width="width"
          :height="height"
          class="m-auto"
          style="background-color:transparent;"
        >
          <v-img :src="`/assets/images/home/logo_castiron${colour}.svg`" />
        </v-sheet>
        <v-sheet
          :width="width"
          :height="height"
          class="m-auto"
          style="background-color:transparent;"
        >
          <v-img :src="`/assets/images/home/logo_vanguard${colour}.svg`" />
        </v-sheet>
      </div>
    </template>
    <template v-else>
      <v-divider v-if="page ==''"/>
      <div class="d-flex w-100">
        <template v-if="brand === 'pme'">
          <v-sheet
            :width="width"
            :height="height"
            class="m-auto"
            style="background-color:transparent;"
          >
            <v-img :src="`/assets/images/home/logo_pmelegend${colour}.svg`" />
          </v-sheet>
        </template>
        <template v-if="brand === 'ci'">
          <v-sheet
            :width="width"
            :height="height"
            style="background-color:transparent;"
            class="m-auto"
          >
            <v-img :src="`/assets/images/home/logo_castiron${colour}.svg`" />
          </v-sheet>
        </template>
        <template v-if="brand === 'vg'">
          <v-sheet
            :width="width"
            :height="height"
            style="background-color:transparent;"
            class="m-auto"
          >
            <v-img :src="`/assets/images/home/logo_vanguard${colour}.svg`" />
          </v-sheet>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "liLogos",
  data: () => ({}),
  props: {
    brand: {
      default: "jb",
      type: String,
    },
    tags: null,
    page: {
      default: "",
      type: String,
    }
  },
  watch: {},
  computed: {
    height(){
      return this.page == ''  ? 60: 100;
    },
    width(){
      return this.page == ''  ? 60: 100;
    },
    colour(){
      if(this.page == ''){
        return  '';
      } else if (this.page ==='jobDetail'){
        return  '_white';
      }  else {
        return  '';
      }
    }
  },
  methods: {},
};
</script>

 