<template>
  <v-container class="w-100 px-0" fluid>
    <template v-if="defaultTextContent != null && location != null && job != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img
          v-if="(location == 'hq' && hqJobDetailHeaderImageContent != null) || location == 'retail'"
          :src="
            location == 'hq'
              ? `${apiPublicUrl}${hqJobDetailHeaderImageContent.image}`
              : currentStore != null && 'content' in currentStore
              ? `${apiPublicUrl}${currentStore.content.image}`
              : `${apiPublicUrl}/uploads/20220404/1649071583_f0f98e139c947962150e.jpg`
          "
          min-height="500"
          max-height="500"
          class="header blur overlay"
          content-class="header-item"
        >
          <v-row class="fill-height mt-3 px-10" align="center" justify="center">
            <div class="white--text text-center">
              <!--
              <h2 class="text-h6 text-md-h5 mb-3 font-weight-light li-text-shadow text-uppercase">
                {{ defaultTextContent.justTheJobForYou }}
              </h2>
              -->
              <h1 class="text-h5 text-sm-h3 text-md-h4 text-lg-h2 li-text-shadow font-weight-black text-md-h4 text-uppercase text-no-break liHeroHeading" v-html="job.title" />
              <v-col cols="12" class="m-auto">
                <!-- DETAILS -->
                <div class="mt-5 text-center">
                  <!-- <liLogos :brand="findStoreBrand(job)" page="jobDetail" :tags="job.tags" /> -->
                  <template v-if="job.address.city != ''">
                    <!-- <hr /> -->
                    <!-- DESKTOP -->
                    <div class="mt-7 mb-7 d-none d-sm-flex">
                      <div class="mx-auto" v-if="job.address.city != ''">
                        <v-icon color="white" class="mr-2">mdi-map-marker</v-icon>
                        <span class="mr-6">{{ job.address.city }}</span>
                      </div>
                      <!-- LOCATION SPECIFIC  HQ DEPARTMENTS-->
                      <template v-if="this.$route.params.location === 'hq'">
                        <div class="mx-auto" v-if="'departments' in job && job.departments.length != 0">
                          <v-icon color="white" class="mr-2">mdi-sitemap-outline</v-icon>
                          <template v-if="job.departments.length > 1">
                            <span class="mr-2" v-for="(department, index) in job.departments" :key="`department${index}`">
                              {{ department }}
                              <template v-if="index != job.departments.length - 1"><span class="mr-2"></span>|</template>
                            </span>
                          </template>
                          <template v-else>
                            <span class="mr-6">{{ job.departments[0] }}</span>
                          </template>
                        </div>
                      </template>
                      <div class="mx-auto" v-if="'hours' in job && job.hours != ''">
                        <v-icon color="white" class="mr-2">mdi-clock-time-eight-outline</v-icon>
                        <span :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-6'"
                          >{{ job.hours }}
                          <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                          {{ defaultTextContent.hour }}</span
                        >
                        <template v-if="'employment' in job && job.employment != ''"> | {{ job.employment[0] | capitalizeFirstLetter }} </template>
                      </div>
                    </div>
                    <!-- MOBILE -->
                    <div class="mt-2 mb-2 d-block d-sm-none text-caption">
                      <span class="d-block mb-">
                        <template v-if="job.address.city != ''">
                          <div class="mx-auto mb-2">
                            <v-icon color="white" small class="mr-2">mdi-map-marker</v-icon>
                            <span class="mr-6">{{ job.address.city }}</span>
                          </div>
                        </template>
                        <!-- LOCATION SPECIFIC  HQ DEPARTMENTS-->
                        <template v-if="this.$route.params.location === 'hq'">
                          <div class="mx-auto mb-3" v-if="'departments' in job && job.departments.length != 0">
                            <v-icon color="white" class="mr-2">mdi-sitemap-outline</v-icon>
                            <template v-if="job.departments.length > 1">
                              <span class="mr-2" v-for="(department, index) in job.departments" :key="`department${index}`">
                                {{ department }}
                                <template v-if="index != job.departments.length - 1"><span class="mr-2"></span>|</template>
                              </span>
                            </template>
                            <template v-else>
                              <span class="mr-6">{{ job.departments[0] }}</span>
                            </template>
                          </div>
                        </template>

                        <template v-if="'hours' in job && job.hours != ''">
                          <div class="mb-3">
                            <v-icon color="white" small class="mr-2">mdi-clock-time-eight-outline</v-icon>
                            <span :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-6'"
                              >{{ job.hours }}
                              <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                              {{ defaultTextContent.hour }}</span
                            >
                            <template v-if="'employment' in job && job.employment != ''"> | {{ job.employment[0] | capitalizeFirstLetter }} </template>
                          </div>
                        </template>
                      </span>
                    </div>
                    <!-- <hr /> -->
                  </template>
                </div>
                <!-- /.END DETAILS -->
              </v-col>
            </div>
          </v-row>
        </v-img>
      </v-row>
      <!-- JOB -->
      <v-row class="mx-0 px-0 bg-light">
        <v-container>
          <v-row class="px-2 px-sm-10 pt-0 pb-10">
            <v-container class="py-0">
              <!-- THE OFFFER -->
              <v-row>
                <v-col cols="12" md="12" lg="12" xl="8" class="pt-0 mx-auto">
                  <v-sheet elevation="3" class="pa-5 pb-10 mb-10" style="margin-top: -50px; position: relative; z-index: 2">
                    <span class="d-block mb-5 text-h6" v-html="job.summary" />
                    <!-- DESCRIPTION -->
                    <span v-html="job.description" />
                    <span class="w-100 mt-7 d-flex"></span>
                    <!-- OFFER -->
                    <span v-html="job.offer" />
                    <span class="w-100 mt-15 d-flex"></span>
                    <!-- CTA -->
                    <div class="text-center">
                      <div v-html="defaultTextContent.jobDetail[location].title" class="text-h5 font-weight-bold mb-2" />
                      <div v-html="defaultTextContent.jobDetail[location].description" class="text-p mb-10" />
                      <v-btn elevation="0" class="btn-cta" :to="`/${language}/${location}/apply/${job.id}`" x-large>
                        <h3 v-text="defaultTextContent.jobDetail[location].button" />
                      </v-btn>
                    </div>
                  </v-sheet>
                  <!-- ABOUT RETAIL-->
                  <template v-if="this.$route.params.location === 'retail' && currentStore != null">
                    <v-row class="mx-0 px-0 mt-0" v-if="currentStore.content.imageStore2 && currentStore.content.imageStore2 != ''">
                      <v-container style="max-width: 1200px">
                        <v-row>
                          <v-col  cols="12" :md="currentStore.content.imageStore2 && currentStore.content.imageStore2 != '' ? 5 : 12">
                            <v-sheet color="white" height="100%">
                              <div class="d-block w-100">
                                <GmapMap
                                  v-if="currentStore.g_lat != '' && currentStore.g_long != ''"
                                  :center="{
                                    lat: Number(currentStore.g_lat),
                                    lng: Number(currentStore.g_long),
                                  }"
                                  :zoom="15"
                                  map-type-id="terrain"
                                  style="width: 100%; height: 300px"
                                  :options="mapOptions"
                                >
                                  <GmapMarker
                                    :icon="markers[currentStore.brand]"
                                    :position="{
                                      lat: Number(currentStore.g_lat),
                                      lng: Number(currentStore.g_long),
                                    }"
                                  />
                                </GmapMap>
                              </div>
                              <div class="pt-10 d-block w-100 px-5">
                                <h5 class="text-h5 mb-3 font-weight-bold liHeroHeading text-uppercase" v-html="currentStore.name" />
                                <p>
                                  <!-- {{ currentStore.street }} <br />
                                  {{ currentStore.custom_street }} <br /> -->
                                  {{ job.address.street }}
                                  <!-- {{ currentStore.number }} <br />
                                  {{ currentStore.custom_number }} <br /> -->
                                  {{ job.address.number }} <br />
                                  <!-- {{ currentStore.postal }}<br />
                                  {{ currentStore.custom_postal }} <br /> -->
                                  {{ job.address.zipcode }}<br />
                                  {{ job.address.city }}
                                </p>
                              </div>
                            </v-sheet>
                          </v-col>
                          <v-col cols="12" md="7" v-if="currentStore.content.imageStore2 && currentStore.content.imageStore2 != ''">
                            <v-sheet color="white"  height="100%">
                              <v-img :src="`${apiPublicUrl}${currentStore.content.imageStore2}`" :aspect-ratio="1.25/1" />
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-row>
                  </template>
                  <!-- DEPARTMENT IMAGE HQ-->
                  <template v-if="this.$route.params.location === 'hq'">
                    <v-img v-if="currentDepartment != null" :src="`${apiPublicUrl}${currentDepartment}`" />
                  </template>
                </v-col>
                <!-- SOCIAL -->
                <v-col cols="12" md="12" lg="12" xl="8" class="pt-0 mx-auto text-center">
                  <v-btn elevation="0" dark fab small class="text-center mx-1 bg-black pa-0" @click="share('fb')">
                    <v-icon color="white"> mdi-facebook </v-icon>
                  </v-btn>
                  <v-btn elevation="0" dark fab small class="text-center mx-1 bg-black pa-0" @click="share('tw')">
                    <v-icon color="white"> mdi-twitter </v-icon>
                  </v-btn>
                  <v-btn elevation="0" dark fab small class="text-center mx-1 bg-black pa-0" @click="share('li')">
                    <v-icon color="white"> mdi-linkedin </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- DEVELOPMENT
              <v-row>
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Development - Available
                      attributes</v-expansion-panel-header
                    >
                    <v-expansion-panel-content style="overflow: hidden">
                      <pre>{{ job }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              -->
            </v-container>
          </v-row>
        </v-container>
      </v-row>
      <!-- LOCATION SPECIFIC -->
      <template v-if="this.$route.params.location === 'retail' && currentStore != null">
        <v-row class="mx-0 px-0 mt-0" v-if="currentStore.imageStore1 && currentStore.imageStore1 != ''">
          <v-img :src="`${apiPublicUrl}${currentStore.imageStore1}`" class="" />
        </v-row>
      </template>
      <template v-if="this.$route.params.location === 'retail' && currentStore != null">
        <!-- BRAND -->
        <v-row class="mx-0 px-0" v-if="ourBrandsBlockContent != null">
          <v-container class="pt-16" fluid>
            <liBrandBlock1 :data="ourBrandsBlockContent" />
          </v-container>
        </v-row>
      </template>
      <template v-if="this.$route.params.location === 'hq'">
        <v-row>
          <v-img v-if="hqJobDetailMoodImageContent != null" :src="`${apiPublicUrl}${hqJobDetailMoodImageContent.image}`" />
        </v-row>
      </template>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "Job",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  computed: {
    retailJobDetailHeaderImage() {
      return "";
    },
    location() {
      return this.$route.params.location != undefined ? this.$route.params.location : null;
    },
    job() {
      if (this.$route.params.jobid != undefined && this.$store.getters["liApiStore/getJobs"](this.location) != null) {
        return this.$store.getters["liApiStore/getJob"](this.location, this.$route.params.jobid);
      } else {
        return null;
      }
    },
    currentDepartment() {
      if (this.location == "hq" && this.departmentItems != null && this.job != null) {
        if (this.job.departments.length != 0) {
          //  return this.job.departments[0]
          // return this.departmentItems
          let department = this.departmentItems.find((department) => department.name.trim() == this.job.departments[0]);
          if (department != undefined) {
            let content = JSON.parse(department.content);
            return content.image;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    currentStore() {
      if (this.location == "retail" && this.storeItems != null && this.job != null && this.defaultTextContent != null) {
        let store = this.storeItems.find((store) => store.fullurl.trim() == `/${this.language}/retail/location/${encodeURIComponent(this.job.address.line)}`);
        // this.storeItems.forEach((store)=>{
        //   if(store.city == 'Den Haag'){
        //     console.log(`/${this.language}/retail/location/${encodeURIComponent(this.job.address.line)}`, store.fullurl);
        //   }
        // })
        // console.log(`/${this.language}/retail/location/${encodeURIComponent(this.job.address.line)}`);
        if (store != undefined) {
          if (typeof store.content == "string") {
            let content = JSON.parse(store.content);
            this.$set(store, "content", content);
          }

          return store;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
  mounted() {
    // console.log("START -- JOB VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- JOB VIEW");
    this.setupPage();
  },
  activated() {
    // console.log("START -- ACTIVATE  JOB VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- ACTIVATE JOB VIEW");
    this.setupPage();
  },

  watch: {
    job(val) {
      if (val != null) {
        this.$title = `Apply for: ${this.job.title} | ${this.location}`;
      }
    },
  },
  created() {
    this.fetchURL();
  },
  methods: {
    setupPage() {
      this.loadJobs();
      if (this.location == "hq") {
        if (this.hqJobDetailHeaderImage == null) {
          this.fetchHqJobHeaderImage();
        }
        if (this.hqJobDetailMoodImage == null) {
          this.fetchHqJobMoodImage();
        }
        if (this.departmentItems == null) {
          this.fetchDepartmentItems();
        }
      }
      if (this.location == "retail") {
        if (this.storeItems == null) {
          this.fetchStoreItems();
        }
      }
      if (this.cultureItems == null) {
        this.fetchCultureItems();
      }
    },
    loadJobs() {
      this.$store.dispatch("liApiStore/GET_JOBS", {
        location: this.location,
        language: this.language,
      });
    },
  },
};
</script>
<style lang="scss">
.squared {
  border-radius: 0;
}
</style>
