import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// PAGE TITLE 
import VuePageTitle from 'vue-page-title'

Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: ' | Jobs | Just Brands '
});

// LINNOX MIXINS
import liMixin from './mixins/liMixin';
Vue.mixin(liMixin)

// GOOGLE MAPS
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA02WTMWr-fGF1EoNeREuub8oUvPXT27Ao',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

// STATIC COMPONENTS
// NAVIGATION
import liNav from './components/static/nav';
Vue.component('liNav', liNav);
// FOOTER
import liFooter from './components/static/footer';
Vue.component('liFooter', liFooter);

// CARROUSEL
import liCarrousel from './components/carrousel/carrousel';
Vue.component('liCarrousel', liCarrousel);
import liCarrouselThunmbs from './components/carrousel/carrouselThumbs';
Vue.component('liCarrouselThunmbs', liCarrouselThunmbs);

// LOGOS
import liLogos from './components/dynamic/logos';
Vue.component('liLogos', liLogos);

// CULTURE ITEMS
import liCultureItems from './components/dynamic/cultureItems';
Vue.component('liCultureItems', liCultureItems);

// BRANDS BLOCK 1
import liBrandBlock1 from './components/dynamic/brandBlock1';
Vue.component('liBrandBlock1', liBrandBlock1);

// VIDEO
import liVimeoVideo from './components/video/liVimeoVideo.vue';
Vue.component('liVimeoVideo', liVimeoVideo);

// Custom filters
import './filters/filters';

// Perfect ScrollBar

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
