<template>
  <v-app-bar fixed dark color="primary" class="liNav scrolled">
    <!-- START / HOME BUTTON / LOGO -->
    <!-- PME  - pme-legend.com -->
    <template v-if="website == 'pme-legend.com'">
      <v-btn
        key="home-desktop"
        text
        class="liLogo dekstop mx-3 lowerCase"
        :to="`/${$route.params.language != undefined ? $route.params.language : ''}`"
      >
        <v-img width="90" :src="PMElogoImageFull" />
      </v-btn>
    </template>
    <!-- Just Brands - justbrands.eu -->
    <template v-if="website == 'justbrands.eu'">
      <v-btn
        key="home-desktop"
        text
        class="liLogo dekstop mx-3 lowerCase ml-md-10"
        :style="scrollPosition < 300 ? 'height: 65px;' : ''"
        :to="`/${$route.params.language != undefined ? $route.params.language : ''}`"
        v-if="!isAppMobile"
      >
        <v-img :width="scrollPosition > 300 ? 100 : 200" :src="logoImageFull" />
      </v-btn>
      <v-btn
        key="home-mobile"
        text
        class="liLogo mx-3 lowerCase ml-md-10"
        :to="`/${$route.params.language != undefined ? $route.params.language : ''}`"
        :width="scrollPosition > 300 ? 30 : 50"
        :height="scrollPosition > 300 ? 30 : 50"
        v-else
      >
        <v-img :aspect-ratio="1 / 1" :src="logoImage" />
      </v-btn>
      <v-spacer />
    </template>
    <!-- EMD / HOME BUTTON / LOGO -->
    <!-- START / NAVIGATION / DESKTOP -->
    <template v-if="!isAppMobile && navigationContent != null">
      <template v-for="(nav, index) in navigationContent">
        <v-btn v-if="nav.status != 0" :key="index" text class="" :to="nav.url">
          {{nav.title}}
        </v-btn>
      </template>
    </template>
    <v-spacer v-if="website == 'pme-legend.com'"/>
    <!-- Language selector -->
    <v-menu bottom left v-if="defaultTextContent != null">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" style="padding: 0px; max-width: 22px; height: unset; min-width: unset; border: 1px solid rgba(255, 255, 255, 0.2)" class="ml-5 mr-md-0">
          <v-img v-if="language != null" :src="flags[language]" width="20px" height="15px;" />
          <v-img v-else :src="flags['nl']" width="20px" height="15px;" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, i) in defaultTextContent.filterCountries"
          :key="i"
          :link="language != item.shortcode"
          :disabled="language == item.shortcode"
          :style="language == item.shortcode ? 'background-color:#f1f1f1;' : ''"
        >
          <v-list-item-title @click.prevent="language = item.shortcode">
            <v-img :src="flags[item.shortcode]" width="20px" height="15px;" style="display: inline-block; margin-right: 10px" />
            {{item.name}}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import logo from "../../assets/images/logo.svg";
import logoFull from "../../assets/images/logo_full.svg";
import pmelogo from "../../assets/images/pme_logo.svg";
import pmelogoFull from "../../assets/images/pme_logo_full.svg";
// import vglogo from "../../assets/images/vg_logo.svg";
// import vglogoFull from "../../assets/images/vg_logo_full.svg";
// import cilogo from "../../assets/images/ci_logo.svg";
// import cilogoFull from "../../assets/images/ci_logo_full.svg";

import flag_nl from "../../assets/images/flags/nl.svg";
import flag_de from "../../assets/images/flags/de.svg";
import flag_be from "../../assets/images/flags/be.svg";
export default {
  name: "liNav",
  data() {
    return {
      logoImage: logo,
      logoImageFull: logoFull,
      PMElogoImage: pmelogo,
      PMElogoImageFull: pmelogoFull,
      flags: {
        be: flag_be,
        de: flag_de,
        nl: flag_nl,
      },
    };
  }
};
</script>
