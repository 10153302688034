<template>
  <v-container class="w-100 px-0" fluid>
    <template v-if="pageContent != null && defaultTextContent != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img :src="`${apiPublicUrl}${pageContent.image}`" min-height="400px" max-height="400px" class="header blur overlay">
          <v-row class="fill-height mt-10" align="center" justify="center">
            <div class="white--text text-center px-10">
              <h1 class="text-lg-h2 text-sm-h3 text-h6 text-no-break text-uppercase liHeroHeading mb-3" v-html="pageContent.headerTitle" />
              <div class="text-sm-h5 text-p white--text text-center li-text-shadow text-uppercase" v-html="pageContent.headerSubTitle" />
            </div>
          </v-row>
        </v-img>
      </v-row>
      <!-- JOBS HEADER -->
      <v-row>
        <v-col cols="12" md="12" lg="12" class="m-auto text-black text-center my-5">
          <h2 class="text-uppercase text-md-h4 text-h4 font-weight-bold" v-if="pageContent.jobstitle && pageContent.jobstitle != ''" v-html="pageContent.jobstitle" />
          <div v-if="pageContent.jobssubtitle && pageContent.jobssubtitle != ''" class="text-p text-center mt-7 mb-7 mx-auto" style="max-width: 1000px" v-html="pageContent.jobssubtitle" />
        </v-col>
        <template v-if="pageContent.videoCode && pageContent.videoCode != ''">
          <v-col cols="12" md="12" lg="12" class="m-auto">
            <liVimeoVideo :vimeoId="pageContent.videoCode" />
          </v-col>
        </template>
      </v-row>
      <!-- JOBS -->
      <v-row class="mx-0 px-0 mb-15 mt-0 bg-light">
        <v-container style="max-width: 1900px">
          <!-- JOB ITEMS -->
          <v-row class="px-sm-10 pb-10 mt-0 jobs">
            <template v-if="filteredJobs != null">
              <template v-if="filteredJobs.length === 0">
                <!-- NO JOB -->
                <v-card tile class="job px-5 py-5" style="display: flex">
                  {{ defaultTextContent.noJobsFound }}
                </v-card>
              </template>
              <template v-else>
                <v-card tile elevation="0" v-for="job in filteredJobs" :key="job.id" class="text-black job d-flex flex-column text-black" :to="`/${language}/${location}/job/${job.id}`">
                  <v-card-title class="text-no-break font-weight-bold text-black text-uppercase liTitle " v-text="job.title"></v-card-title>
                  <v-card-subtitle class="mt-1 text-no-break text-black" v-html="job.summary"></v-card-subtitle>
                  <v-spacer></v-spacer>
                  <!-- <liLogos  :brand="findStoreBrand(job)" :tags="job.tags"/> -->
                  <v-divider v-if="job.address.city != '' || ('hours' in job && job.hours != '')" />
                  <v-card-actions>
                    <template v-if="job.address.city != ''">
                      <v-icon small class="mr-1">mdi-map-marker</v-icon>
                      <span class="text-caption mr-4">{{ job.address.city }}</span>
                    </template>
                    <template v-if="'hours' in job && job.hours != ''">
                      <v-icon small class="mr-1">mdi-clock-time-eight-outline</v-icon>
                      <span class="text-caption" :class="'employment' in job && job.employment != '' ? 'mr-1' : 'mr-4'"
                        >{{ job.hours }}
                        <span v-if="'hoursTo' in job && job.hoursTo != '' && job.hours != job.hoursTo">- {{ job.hoursTo }}</span>
                        {{ defaultTextContent.hour }}</span
                      >
                      <template v-if="'employment' in job && job.employment != ''">
                        <span class="text-caption mr-4">
                          |
                          {{ job.employment[0] | capitalizeFirstLetter }}</span
                        >
                      </template>
                    </template>
                    <template v-if="'departments' in job && job.departments.length > 0">
                      <v-icon small class="mr-1">mdi-sitemap-outline</v-icon>
                      <span class="text-caption">
                        {{ job.departments[0] | capitalizeFirstLetter }}
                      </span>
                    </template>
                  </v-card-actions>
                </v-card>
              </template>
            </template>
            <template v-else>
              <div class="text-center w-100">
                <v-progress-circular indeterminate color="primary" />
              </div>
            </template>
          </v-row>
        </v-container>
      </v-row>
      <!-- IMAGE -->
      <v-row class="mx-0 px-0 mt-0" v-if="pageContent.imageDepartment1 && pageContent.imageDepartment1 != ''">
        <v-container fluid class="px-0">
          <v-img :src="`${apiPublicUrl}${pageContent.imageDepartment1}`" class="" />
        </v-container>
      </v-row>
      <!-- ABOUT -->
      <v-row
        class="mx-0 px-0 mt-0 ABOUT"
        v-if="
          (pageContent.departmentDescriptionTitle && pageContent.departmentDescriptionTitle != '') ||
          (pageContent.departmentDescriptionText && pageContent.departmentDescriptionText != '') ||
          (pageContent.imageDepartment2 && pageContent.imageDepartment2 != '')
        "
      >
        <v-container style="max-width: 1400px">
          <v-row>
            <v-col
              cols="12"
              :md="pageContent.imageDepartment2 && pageContent.imageDepartment2 != '' ? 5 : 12"
              v-if="(pageContent.departmentDescriptionTitle && pageContent.departmentDescriptionTitle != '') || (pageContent.departmentDescriptionText && pageContent.departmentDescriptionText != '')"
            >
              <v-sheet color="#ffffff" class="py-5 px-5 py-md-10 px-md-10 d-flex" height="100%">
                <div class="align-self-center">
                  <h5 class="text-h5 mb-3 font-weight-bold" v-html="pageContent.departmentDescriptionTitle" />
                  <span v-html="pageContent.departmentDescriptionText" />
                </div>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="7" v-if="pageContent.imageDepartment2 && pageContent.imageDepartment2 != ''">
              <v-sheet color="#ffffff" class="py-md-10" height="100%">
                <v-img :src="`${apiPublicUrl}${pageContent.imageDepartment2}`" class="" v-if="pageContent.imageDepartment2 && pageContent.imageDepartment2 != ''" />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!-- CARROUSEL -->
      <v-row class="mx-0 px-0 mb-15" v-if="pageContent.departmentSlider && pageContent.departmentSlider != '' && pageContent.departmentSlider != '[]'">
        <v-container style="max-width: 1400px">
          <v-row>
            <v-col cols="12" md="12" lg="12" class="m-auto">
              <div class="primary--text mt-5 text-center">
                <div class="my-3 text-h2 font-weight-bold" v-if="pageContent.teamDepartmentTitle && pageContent.teamDepartmentTitle != ''" v-html="pageContent.teamDepartmentTitle" />
                <div
                  v-if="pageContent.teamDepartmentSubTitle && pageContent.teamDepartmentSubTitle != ''"
                  class="text-p text-center mt-7 mb-7 mx-auto"
                  style="max-width: 1000px"
                  v-html="pageContent.teamDepartmentSubTitle"
                />
              </div>
            </v-col>
          </v-row>
          <liCarrousel :data="JSON.parse(pageContent.departmentSlider)" />
        </v-container>
      </v-row>
      <!-- CULTURE -->
      <v-row class="mx-0 px-0 bg-light">
        <v-container class="pb-16 pt-12" style="max-width: 1400px">
          <!-- CULTURE TITLE -->
          <v-col cols="12" md="12" lg="12" class="pt-0 m-auto text-center text-black">
            <h2 class="text-md-h4 text-h4 mb-3 text-black" v-html="cultureTitleBlockContent.culturetitle" />
            <div class="text-p text-center mb-7" v-html="cultureTitleBlockContent.culturetext" />
          </v-col>
          <!-- CULTURE ITEMS  -->
          <liCultureItems v-if="cultureItems != null" :key="fullUrl" :itemsToShow="3" />
        </v-container>
      </v-row>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>
<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "HqDepartment",
  data() {
    return {
      apiPublicUrl: API_URL,
      filterPlaces: null,
      filterEmployment: null,
      filterDepartment: null,
      filters: {
        Field: "departments",
        Values: [decodeURI(this.fullUrl)],
      },
    };
  },
  computed: {
    departmentNoOfItems() {
      if (this.isAppMobile) {
        return 1;
      } else if (this.isAppTablet) {
        return 3;
      } else {
        return 4;
      }
    },
    departmentNoOfCols() {
      if (this.isAppMobile) {
        return 12;
      } else if (this.isAppTablet) {
        return 4;
      } else {
        return 3;
      }
    },
    location: {
      get() {
        return "hq";
      },
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
    jobs() {
      return this.$store.getters["liApiStore/getJobs"](this.location);
    },
    filteredJobs() {
      if (this.jobs != null && this.$route.params.departmentLine) {
        let filtered = [];
        this.jobs.forEach((job) => {
          if (job.departments != undefined && job.departments.length > 1) {
            job.departments.forEach((department) => {
              if (department.toLowerCase() == this.$route.params.departmentLine) {
                filtered.push(job);
              }
            });
          } else if (job.departments != undefined && job.departments.length == 1) {
            if (job.departments[0].toLowerCase() == this.$route.params.departmentLine) {
              filtered.push(job);
            }
          }
        });
        return filtered;
      } else {
        return null;
      }
    },
    places() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.address != null && job.address != undefined && job.address != "" && job.address.city != "") {
            if (!filterItems.some((item) => item.line === job.address.line)) {
              filterItems.push(job.address);
            }
          }
        });
        return filterItems.sort((a, b) => (a.city > b.city ? 1 : a.city === b.city ? (a.size > b.size ? 1 : -1) : -1));
      } else {
        return null;
      }
    },
    employments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.employment != null && job.employment != undefined && job.employment != "") {
            if (job.employment.length > 1) {
              job.employment.forEach((employment) => {
                if (!filterItems.includes(employment)) {
                  filterItems.push(this.capitalizeFirstLetter(employment));
                }
              });
            } else {
              if (!filterItems.includes(job.employment[0])) {
                filterItems.push(this.capitalizeFirstLetter(job.employment[0]));
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
    departments() {
      if (this.jobs != null) {
        let filterItems = [];
        this.jobs.forEach((job) => {
          if (job.departments != null && job.departments != undefined && job.departments != "") {
            if (job.departments.length > 1) {
              job.departments.forEach((item) => {
                if (!filterItems.includes(item)) {
                  filterItems.push(item);
                }
              });
            } else {
              if (!filterItems.includes(job.departments[0])) {
                filterItems.push(job.departments[0]);
              }
            }
          }
        });
        return filterItems.sort();
      } else {
        return null;
      }
    },
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
    filterPlaces(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
        this.filters.push({ Field: "address.city", Values: [val.city] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "address.city");
      }
    },
    filterEmployment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
        this.filters.push({ Field: "employment", Values: [val.toLowerCase()] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "employment");
      }
    },
    filterDepartment(val) {
      if (val != null) {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
        this.filters.push({ Field: "departments", Values: [val] });
      } else {
        this.filters = this.filters.filter((filter) => filter.Field != "departments");
      }
    },
  },
  created() {
    this.fetchURL();
  },
  mounted() {
    // console.log("START -- HQ JOBS VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- HQ JOBS VIEW");
    this.setupPage();
  },
  methods: {
    setupPage() {
      this.fetchDepartmentData();
      this.loadJobs();
      if (this.departmentItems == null) {
        this.fetchDepartmentItems();
      }
      if (this.cultureItems == null) {
        this.fetchCultureItems();
      }
    },
    loadJobs() {
      this.$store.dispatch("liApiStore/GET_JOBS", {
        location: this.location,
        language: this.language,
      });
    },
  },
};
</script>
